import { Bets } from '@pages/auth/historic/Bets';
import { Deposits } from '@pages/auth/historic/Deposits';

import { Profile } from '@pages/auth/admin/Profile';
import { AddCash } from '@pages/auth/approvals/AddCash';
import { RemoveCash } from '@pages/auth/approvals/RemoveCash';
import { ApprovalWithdrawCash } from '@pages/auth/approvals/WithdrawCash';
import { Customization } from '@pages/auth/customization/Customization';
import { General } from '@pages/auth/financial/General';
import { GameInfo } from '@pages/auth/games/GameInfo';
import { Games } from '@pages/auth/games/Games';
import { Withdraw } from '@pages/auth/historic/Withdraw';
import { Home } from '@pages/auth/Home';
import { LogsAdmin } from '@pages/auth/logs/Admin';
import { LogsCustomer } from '@pages/auth/logs/Customer';
import { UserEdit } from '@pages/auth/users/Edit';
import { UserInfo } from '@pages/auth/users/Info';
import { Users } from '@pages/auth/users/Users';
import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';

export default function AppRoutes() {
    return (
        <>
            <Routes>
                {/* Rotas protegidas */}
                <Route element={<ProtectedRoute />}>
                    <Route path="/" element={<Home />} />

                    {/* Users */}
                    <Route path="/users" element={<Users />} />
                    <Route path="/user/info/:id" element={<UserInfo />} />
                    <Route path="/user/edit/:id" element={<UserEdit />} />

                    {/* Historic */}
                    <Route path="/historic/deposits" element={<Deposits />} />
                    <Route path="/historic/withdraw" element={<Withdraw />} />
                    <Route path="/historic/bets" element={<Bets />} />

                    {/* Approvals */}
                    <Route path="/withdraw/cash" element={<ApprovalWithdrawCash />} />
                    <Route path="/addition/cash" element={<AddCash />} />
                    <Route path="/remove/cash" element={<RemoveCash />} />

                    {/* Financial */}
                    <Route path="/financial/general" element={<General />} />

                    {/* Admin */}
                    <Route path="/profile" element={<Profile />} />

                    {/* Logs */}
                    <Route path="/logs/customer" element={<LogsCustomer />} />
                    <Route path="/logs/admin" element={<LogsAdmin />} />

                    {/* Games */}
                    <Route path="/games" element={<Games />} />
                    <Route path="/games/:id" element={<GameInfo />} />


                    {/* Layout & Customizations */}
                    <Route path="/customization" element={<Customization />} />

                    {/* Layout & Customizations */}
                    <Route path="/customization" element={<Customization />} />


                </Route>
            </Routes>
        </>
    );
}
