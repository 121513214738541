import { Card } from "@components/cards/card";
import { InputSearch } from "@components/forms/input-search";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IListCustomerDTO } from "@hooks/dtos/customer/ICustomerDTO";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FaEye, FaPencil } from "react-icons/fa6";


const status = {
  a: {
    color: "success",
    text: "Ativo",
  },
  p: {
    color: "primary",
    text: "Pendente",
  },
  i: {
    color: "warning",
    text: "Inativo",
  },
  x: {
    color: "danger",
    text: "Excluído",
  },
}

const masks = new Masks();
export function Users() {
  const { actions: { pushToastify } } = ZuToastifyStore()
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [customers, setCustomers] = useState<IListCustomerDTO[]>([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  async function listCustomers(page: number, search: string = "") {
    try {
      const response = await api.post(`/manager/customers/list`, { page, search });
      setCustomers(response.data.result);
      setTotalPages(response.data.pages);
    } catch (err: any) {
      pushToastify({
        message: err?.response?.data.message ?? "Ocorreu um erro!",
        type: 'error',
        theme: 'dark',
      });
    }
    setIsLoading(false);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch(e.target.value);
  };

  function notify() {
    pushToastify({
      message: 'Em construção!',
      type: 'info',
      theme: 'dark',
    });
  }

  useEffect(() => {
    setIsLoading(true);

    const delayDebounceFn = setTimeout(() => {
      setPage(1);
      listCustomers(1, search);
    }, 2500);

    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  useEffect(() => {
    listCustomers(page);
  }, [page]);

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card >
            <div className="card-header">
              <div className="row">
                <div className="col-md-10">
                  <div className="card-title">Usuários</div>
                  <div className="card-category">Tabela respectiva aos usuários do sistema.</div>
                </div>

                <div className="col-md-2">
                  <InputSearch
                    animated
                    label="Buscar"
                    placeholder=""
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Usuário</th>
                      <th>Email</th>
                      <th>Documento</th>
                      <th>Status</th>
                      <th>Criação</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={10} className="text-center">
                          <LoadingSkeleton />
                        </td>
                      </tr>
                    ) : customers.length > 0 ? (
                      customers.map((customer, index) => (
                        <tr key={customer.id}>
                          <th scope="row" className="text-start">{customer.id}</th>
                          <td className="text-start">{customer.userName}</td>
                          <td className="text-start">{customer.email}</td>
                          <td className="text-start">{masks.cpf(customer.document)}</td>
                          <td className="text-start">
                            <span className={`fs-6 badge badge-${status[customer.status].color}`}>
                              {status[customer.status].text}
                            </span>
                          </td>
                          <td className="text-start">{dayjs(customer.createdAt).format('YYYY/MM/DD [ás] HH:mm:ss')}</td>
                          <td className="d-flex flex-md-row flex-column align-items-center justify-content-start">
                            <a href={`/user/info/${customer.id}`} className="btn btn-sm text-muted">
                              <FaEye />
                            </a>
                            <a href={`/user/edit/${customer.id}`} onClick={() => notify()} className="btn btn-sm text-muted">
                              <FaPencil />
                            </a>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">Nenhum registro encontrado</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <Pagination setPage={setPage} page={page} totalPages={totalPages} maxPageShow={2} />
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}