/* eslint-disable no-unused-vars */
import { create } from 'zustand'
import { IManagerDTO } from '../dtos/user-dto'

type IActionsProps = {
  setManager: (manager: IManagerDTO | undefined) => void
  checkIfHaveSession: () => Promise<void>
}

type IStoreProps = {
  manager: IManagerDTO | undefined
  actions: IActionsProps
}

export const ZuAuthStore = create<IStoreProps>((set) => ({
  manager: undefined,
  actions: {
    setManager: (value) => {
      localStorage.setItem('@yolo:manager', JSON.stringify(value))
      return set(() => ({ manager: value }))
    },
    checkIfHaveSession: async () => {
      const data = localStorage.getItem('@yolo:manager')
      return set(() => ({ manager: data ? JSON.parse(data) : undefined }))
    },
  },
}))
