import admin from '@assets/img/admin.jpg';
import { Card } from "@components/cards/card";
import { InputGroupProfile } from '@components/forms/input-group-profile';
import { IManagerDTO } from "@hooks/dtos/user-dto";
import { ZuToastifyStore } from '@hooks/stores/zustand-toastify-store';
import { api } from "@service/api";
import { useEffect, useState } from "react";
import background from '@assets/img/bg.jpg';

const status = {
    a: {
        color: "success",
        text: "Ativo",
    },
    i: {
        color: "warning",
        text: "Inativo",
    },
    x: {
        color: "danger",
        text: "Excluído",
    },
    p: {
        color: "secondary",
        text: "Pendente",
    },
};

export function Profile() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userData, setUserData] = useState<IManagerDTO | null>(null);

    const {
        actions: { pushToastify }
    } = ZuToastifyStore();

    async function verifyPasswordAdmin() {
        try {
            const response = await api.post(`/manager/password/update`, {
                oldPassword,
                newPassword,
                confirmPassword
            });

            pushToastify({
                message: response.data.message || 'Senha alterada com sucesso!',
                type: 'success',
                theme: 'dark',
            });
        } catch (err: any) {
            const errorResponse = err.response?.data;
            pushToastify({
                message: errorResponse?.message || 'Ocorreu um erro ao alterar a senha!',
                type: 'error',
                theme: 'dark',
            });
        }
    }

    useEffect(() => {
        const savedData = localStorage.getItem('@yolo:manager');
        if (savedData) {
            const parsedData: IManagerDTO = JSON.parse(savedData);
            setUserData(parsedData);
        }
    }, []);

    return (
        <>
            <div className="container">
                <div className="row align-items-stretch">
                    <div className="col-md-4 d-flex">
                        <Card className="flex-fill card card-profile">
                            <div className="card-header rounded-top-3" style={{ backgroundImage: `url(${background})`, backgroundSize: "cover" }}>
                                <div className="profile-picture">
                                    <div className="avatar avatar-xxl">
                                        <img src={admin} alt="..." className="avatar-img rounded-circle" />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="user-profile text-center">
                                    {userData ? (
                                        <div>
                                            <div className="name text-capitalize">Nome: {userData?.user?.name ?? ''}</div>
                                            <div className="job text-capitalize">Login: {userData?.user?.login ?? ''}</div>
                                            <div className="job text-capitalize">Idioma: {userData?.user?.language ?? ''}</div>
                                            <div className="job text-capitalize">
                                                Status:
                                                <span className={`fs-8 badge bg-${status[userData?.user.status]?.color ?? ''}`}>
                                                    {status[userData?.user.status]?.text ?? ''}
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <p>Nenhum dado de usuário disponível</p>
                                    )}
                                </div>
                            </div>
                        </Card>
                    </div>

                    <div className="col-md-8 d-flex">
                        <Card className="flex-fill">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <InputGroupProfile
                                            animated
                                            label="Senha Antiga"
                                            placeholder=""
                                            type='password'
                                            value={oldPassword}
                                            onChange={(e) => setOldPassword(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <InputGroupProfile
                                            animated
                                            label="Nova senha"
                                            placeholder=""
                                            type='password'
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-md-12">
                                        <InputGroupProfile
                                            animated
                                            label="Confirme a senha"
                                            placeholder=""
                                            type='password'
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 text-end mt-5">
                                    <button className="btn btn-secondary" onClick={verifyPasswordAdmin}>Alterar</button>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}
