import profile from "@assets/img/defaultAvatar.png";
import { ConfirmButton } from "@components/buttons/confirm-button";
import { Card } from "@components/cards/card";
import { Input } from "@components/forms/input";
import { yupResolver } from "@hookform/resolvers/yup";
import { ICustomerInfo } from "@hooks/dtos/customer/ICustomerDTO";
import { ISystemConfigDTO } from "@hooks/dtos/ISystemConfigDTO";
import { IManagerSecretDTO } from "@hooks/dtos/manager/IManagerDTO";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import * as yup from 'yup';

interface IForm {
    name: string;
    userName: string;
    email: string;
    document: string;
    pin?: string;
    otp?: string;
}

interface IFormCommission {
    [key: string]: number | undefined;
}

interface ISystemConfig {
    name: string;
    value: string;
    description: string;
}

interface IUserCommissionDTO {
    levels: {
        [key: number]: number;
    };
    enabled: boolean;
}

export function UserEdit() {
    const { actions: { pushToastify } } = ZuToastifyStore();

    const { id } = useParams<string>();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [managerSecrets, setManagerSecrets] = useState<IManagerSecretDTO>({});

    const [customer, setCustomer] = useState<ICustomerInfo>({} as ICustomerInfo);
    const [file, setFile] = useState<File | null>(null);
    const [preview, setPreview] = useState();

    const [isLoadingCommissionConfig, setIsLoadingCommissionConfig] = useState<boolean>(true);
    const [userCommission, setUserCommission] = useState<IUserCommissionDTO>();
    const [commissionConfig, setCommissionConfig] = useState<ISystemConfigDTO>();

    const scheme = yup.object().shape({
        name: yup.string()
            .required('Digite o nome do Usuário.'),
        userName: yup.string()
            .required('Digite o UserName.'),
        email: yup.string()
            .required('Digite o E-mail.'),
        document: yup.string()
            .required('Digite o Documento do Usuário.'),
    });

    const { register, reset, setValue, handleSubmit, formState: { errors } } = useForm<IForm>({
        resolver: yupResolver(scheme),
        mode: 'onSubmit'
    });

    async function getCustomerInfo() {
        try {
            const response = await api.post(`/manager/customer/info`, { customerId: Number(id) });
            if (response.data.customer) {
                setCustomer(response.data);
                
                reset({
                    name: response.data.customer.name,
                    userName: response.data.customer.userName,
                    email: response.data.customer.email,
                    document: response.data.customer.document,
                })
            }

            // --- Load the User Commission Configs --- //
            const usrDataConfig = response.data?.config;
            if (usrDataConfig) {
                const usrCommission = JSON.parse(usrDataConfig?.config);
                setUserCommission(usrCommission?.commission);
            } else {
                pushToastify({
                    message: `Houve um problema ao carregar informações de Comissionamento deste Usuário.`,
                    type: 'error',
                    theme: 'dark',
                });
            }

        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
    }

    async function getSystemConfig() {
        try {
            const configs = ["commission"];
            const response = await api.post(`/manager/system/configs`, { configs });
            if (response.data) {
                const commission = getConfigByName(response.data, "commission");
                if (commission && commission.value) {
                    const parsedConfig = JSON.parse(commission.value);
                    setCommissionConfig(parsedConfig as {});
                    setTimeout(() => setIsLoadingCommissionConfig(false), 10);
                } else {
                    pushToastify({
                        message: `Houve um erro ao carregar informações de comissionamento do Sistema.`,
                        type: 'error',
                        theme: 'dark',
                    });
                }
            }
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
    }

    function getConfigByName(configs: ISystemConfig[], name: string) {
        return configs.find(config => config.name === name);
    }

    async function editCustomerInfo(data: IForm) {
        try {
            await api.post('/manager/update/customer', {
                customerId: customer.encryptedId,
                pin: data.pin,
                otpAuth: data.otp,
                name: data.name,
                userName: data.userName,
                email: data.email,
                document: data.document
            });

            pushToastify({
                message: "Sucesso ao editar usuário!",
                type: 'success',
                theme: 'dark',
            });
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
    }

    async function handleChangeAvatar(data: { pin: string | undefined, otp: string | undefined }) {
        if (!file) {
            pushToastify({
                message: 'Selecione uma imagem',
                type: 'warning',
                theme: 'dark'
            });
            return;
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
            const base64 = reader.result as string;
            try {
                await api.post(`/manager/update/avatar`, {
                    customerId: customer.encryptedId,
                    pin: data.pin,
                    otpAuth: data.otp,
                    file: base64
                });

                pushToastify({
                    message: 'Foto de perfil atualizada com sucesso!',
                    type: 'success',
                    theme: 'dark'
                });
            } catch (error: any) {
                pushToastify({
                    message: error.response?.data || 'Ocorreu um erro',
                    type: 'error',
                    theme: 'dark'
                });
            }
        };
        reader.readAsDataURL(file);
    }

    function handleImageClick() {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setFile(file);

            const reader = new FileReader();
            reader.onload = (event: any) => {
                setPreview(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    function generateValidationSchema(maxLevel: number) {
        const schemaFields: { [key: string]: yup.NumberSchema } = {};

        schemaFields[`enabled`] = yup
            .number()
            .required(`Selecione um status válido para o comissionamento deste Usuário.`);

        for (let i = 1; i <= maxLevel; i++) {
            const field = `level${i}`;
            let fieldSchema = yup
                .number()
                .required(`Digite o valor de comissão para o ${field}.`)
                .min(0, 'O valor deve ser maior ou igual a zero.')
                .max(100, 'O valor máximo permitido é 100.');
            
            schemaFields[field] = fieldSchema;
        }

        return yup.object().shape(schemaFields);
    }

    const schemeCommission = generateValidationSchema(commissionConfig?.maxLevel);

    const { register: registerCommission, handleSubmit: handleSubmitCommission, formState: { errors: errorsCommission } } = useForm<IFormCommission>({
        resolver: yupResolver(schemeCommission),
        mode: 'onSubmit',
    });

    async function editCustomerCommission(data: IFormCommission) {
        try {
            // --- Create/concatenate the data to send --- //
            const updatedData = { ...data, customerId: customer.encryptedId, pin: managerSecrets?.pin, otpAuth: managerSecrets?.otp };

            // --- Send to API to change --- //
            await api.post('/manager/update/customer/commission', updatedData);

            // --- Reset the Secrets Values (PIN & OTP) --- //
            setManagerSecrets({});

            pushToastify({
                message: "Configurações de comissões alteradas com sucesso!",
                type: 'success',
                theme: 'dark',
            });
        
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
    }

    useEffect(() => {
        getCustomerInfo();
        getSystemConfig();
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Card>
                        <div className="card-header">
                            <div className="card-title">
                                Edição do Usuário @{customer?.customer?.userName} (#{id})
                            </div>
                            <div className="card-category p-0 m-0">
                                <small>Informações primárias do Usuário</small>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-4 text-center">
                                    <div className="row">
                                        <div className="col-md-12 ">
                                            <div className="position-relative rounded-circle overflow-hidden cursor-pointer ms-auto me-auto" style={{ width: '150px', height: '150px' }} >
                                                <img
                                                    src={preview || customer?.customer?.photos || profile}
                                                    alt="User Avatar"
                                                    className="img-fluid w-100 h-100 rounded-circle"
                                                    style={{ objectFit: "cover", cursor: "pointer" }}
                                                />
                                                <div className="overlay d-flex justify-content-center align-items-center w-100 h-100 top-0 left-0 position-absolute" onClick={handleImageClick}>
                                                    <span className="text-white text-center">Clique para escolher outra foto</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-3 mb-3 mb-md-0">
                                            <input
                                                type="file"
                                                className="form-control d-none"
                                                id="inputGroupFile02"
                                                ref={fileInputRef}
                                                onChange={handleImageChange}
                                                accept=".jpg, .jpeg,"
                                            />
                                            <div className="mt-2 col-md-12 text-end">
                                                <ConfirmButton
                                                    className="btn btn-purple w-100"
                                                    validations="doubleCheck"
                                                    title="Alterar Foto de perfil"
                                                    textConfirmation="Confirme sua senha e PIN para alterar o avatar!"
                                                    needConfirm={true}
                                                    onConfirm={(e, data) => {
                                                        handleChangeAvatar({
                                                            pin: data?.pin,
                                                            otp: data?.otp
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                    }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input
                                                    register={register('name')}
                                                    placeholder=""
                                                    label="Nome"
                                                    animated
                                                    errorMessage={String(errors?.name?.message || "")}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <Input
                                                    register={register('userName')}
                                                    placeholder=""
                                                    label="Nome de usuário"
                                                    animated
                                                    errorMessage={String(errors?.userName?.message || "")}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <Input
                                                    register={register('email')}
                                                    placeholder=""
                                                    label="Email"
                                                    animated
                                                    errorMessage={String(errors?.email?.message || "")}
                                                />
                                            </div>

                                            <div className="col-md-6">
                                                <Input
                                                    register={register('document')}
                                                    placeholder=""
                                                    label="CPF"
                                                    animated
                                                    errorMessage={String(errors?.document?.message || "")}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 text-end">
                                                <ConfirmButton
                                                    validations="doubleCheck"
                                                    textConfirmation="Por favor, confirme sua Senha, OTP e o envio do Formulário para a requisição ser feita!"
                                                    needConfirm
                                                    onConfirm={(e, data) => {
                                                        if (e === true) {
                                                            setValue("pin", data?.pin);
                                                            setValue("otp", data?.otp);
                                                            handleSubmit(editCustomerInfo)();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <Card>
                        <div className="card-header">
                            <div className="card-title">
                                Comissionamento
                            </div>
                            <div className="card-category p-0 m-0">
                                <small>Trata-se do percentual de comissionamento em níveis do respectivo Usuário</small>
                            </div>
                        </div>
                        <div className="card-body">
                            {isLoadingCommissionConfig ? (
                                <Skeleton count={1} height={50} />
                            ) : (
                                <div className="col-md-12">
                                    <form onSubmit={(f) => {
                                        f.preventDefault();
                                    }}>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="form-floating mb-3">
                                                    <select
                                                        className={`form-select`}
                                                        {...registerCommission('enabled')}
                                                        defaultValue={Number(userCommission?.enabled) || 0}
                                                    >
                                                        <option key="0" value="0">{`Inativo`}</option>
                                                        <option key="1" value="1">{`Ativo`}</option>
                                                    </select>
                                                    <label>Status</label>
                                                </div>

                                                {Array.from({ length: commissionConfig?.maxLevel || 0 }, (_, index) => {
                                                    const level = index + 1;
                                                    const fieldName = `level${level}`;
                                                    return (
                                                        <div key={index}>
                                                            <Input
                                                                animated
                                                                register={registerCommission(fieldName)}
                                                                placeholder=""
                                                                label={`${level}º Nível`}
                                                                defaultValue={Number(userCommission?.levels[level] || 0)}
                                                                errorMessage={String(errorsCommission[fieldName]?.message || "")}
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 text-end">
                                                <ConfirmButton
                                                    validations="doubleCheck"
                                                    textConfirmation="Por favor, insira sua senha e o OTP para continuar com a requisição!"
                                                    needConfirm={true}
                                                    onConfirm={(f, data) => {
                                                        if (f === true) {
                                                            managerSecrets.pin = data?.pin;
                                                            managerSecrets.otp = data?.otp;
                                                            handleSubmitCommission(editCustomerCommission)();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            )}
                        </div>
                    </Card>
                </div>
            </div>
        </>
    );
}
