import { ZuAuthStore } from '@hooks/stores/zustand-auth-store';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = () => {
    const { manager, actions } = ZuAuthStore();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const verifySession = async () => {
            await actions.checkIfHaveSession();
            setLoading(false);
        };

        verifySession();
    }, [actions]);


    if (loading) {
        return <></>;
    }

    return manager ? <Outlet /> : <Navigate to="/home" />;
    };

    export default ProtectedRoute;
