import ThemeProvider from 'react-bootstrap/ThemeProvider';

import { AuthProvider } from '@hooks/contexts/auth-context';
import { BrowserRouter } from 'react-router-dom';

import { ModalProvider } from '@hooks/contexts/modal-context';
import { SidebarCollapseProvider } from '@hooks/contexts/SidebarCollapseContext';
import { TopbarCollapseProvider } from '@hooks/contexts/TopbarCollapseContext';

import Content from '@components/layout/content/content';
import { ZuAuthStore } from '@hooks/stores/zustand-auth-store';
import { Auth } from '@pages/public/Auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
    const { manager } = ZuAuthStore();

    return (
        <>
            <ThemeProvider
                breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
                minBreakpoint="xxs" >
                <AuthProvider>
                    {!manager ? (
                        <>
                            <Auth />
                        </>
                    ) : (
                        <>
                            <BrowserRouter>
                                <ModalProvider>
                                    <TopbarCollapseProvider>
                                        <SidebarCollapseProvider>
                                            <Content />
                                        </SidebarCollapseProvider >
                                    </TopbarCollapseProvider>
                                </ModalProvider>
                            </BrowserRouter>
                        </>
                    )}
                    <ToastContainer />
                </AuthProvider>
            </ThemeProvider>
        </>
    );
}


export default App;
