import { Card } from "@components/cards/card";
import { Pagination } from "@components/pagination";
import { useState } from "react";
import { FaEye, FaPencil } from "react-icons/fa6";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";




export function Games() {
    const [page, setPage] = useState(1);
    const { actions: { pushToastify } } = ZuToastifyStore()

    function notify() {
        pushToastify({
            message: 'Em construção!',
            type: 'info',
            theme: 'dark',
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Card className="card-round">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-10">
                                    <h4 className="card-title">Jogos</h4>
                                    <p className="card-category">Lista de todos os jogos</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id="basic-datatables_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                                    <div id="basic-datatables_length" className="dataTables_length">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr role="row">
                                                    <th scope="col" className="fs-6">#</th>
                                                    <th>Jogos</th>
                                                    <th>Tipo</th>
                                                    <th>Probabilidade de Ganhar</th>
                                                    <th>Configurações</th>
                                                    <th>Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={5} className="text-center">Nenhum registro encontrado.</td>
                                                    <td className="d-flex flex-md-row flex-column align-items-center justify-content-start">
                                                        <a href={`/games/1`} className="btn btn-sm text-muted">
                                                            <FaEye />
                                                        </a>
                                                        <a onClick={() => notify()} className="btn btn-sm text-muted">
                                                            <FaPencil />
                                                        </a>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <Pagination page={page} setPage={setPage} totalPages={1} maxPageShow={1} />
                        </div>
                    </Card >
                </div >
            </div >
        </>
    );
}
