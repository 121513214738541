import { Card } from "@components/cards/card";
import { InputSearch } from "@components/forms/input-search";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IDepositPaymentDTO } from "@hooks/dtos/IDepositDto";
import { Masks } from "@hooks/functions/Masks/Masks";
import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { encodeBase64 } from './../../../hooks/functions/base64';
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";

const status = {
    p: {
        color: "success",
        text: "Pago",
    },
    w: {
        color: "warning",
        text: "Pendente",
    },
    h: {
        color: "warning",
        text: "Escondido"
    },
    x: {
        color: "danger",
        text: "Deletado",
    }
}

const masks = new Masks();
export function Deposits() {
    const [isLoading, setIsLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [deposits, setDeposits] = useState<IDepositPaymentDTO[]>([]);
    const [search, setSearch] = useState("");
    const { actions: { pushToastify } } = ZuToastifyStore()

    async function historicDeposits(page: number, search: string = "") {
        try {
            const response = await api.post(`/manager/deposits/list`, {
                search: search,
                page: page
            });
            setDeposits(response.data.result);
            setTotalPages(response.data.pages);
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
        setIsLoading(false);
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSearch(e.target.value);
    };

    useEffect(() => {
        historicDeposits(page);
    }, [page]);

    useEffect(() => {
        setIsLoading(true);
        const delayDebounceFn = setTimeout(() => {
            setPage(1)
            historicDeposits(1, search);
        }, 2500);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    return (
        <div className="row">
            <div className="col-md-12">
                <Card className="card-round">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-10">
                                <h4 className="card-title">Depósitos</h4>
                                <p className="card-category">Lista de todos os depósitos</p>
                            </div>
                            <div className="col-md-2">
                                <InputSearch animated label="Buscar" placeholder="" value={search} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div id="basic-datatables_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                                <div id="basic-datatables_length" className="dataTables_length">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr role="row">
                                                <th scope="col" className="fs-6">#</th>
                                                <th scope="col" className="fs-6">Usuário</th>
                                                <th scope="col" className="fs-6">Documento</th>
                                                <th scope="col" className="fs-6">Cod. Deposito</th>
                                                <th scope="col" className="fs-6">Método</th>
                                                <th scope="col" className="fs-6">Solicitado</th>
                                                <th scope="col" className="fs-6">Pago</th>
                                                <th scope="col" className="fs-6">Taxa</th>
                                                <th scope="col" className="fs-6">Status</th>
                                                <th scope="col" className="fs-6">Pagamento</th>
                                                {/* <th scope="col" className="fs-6">Ações</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <tr>
                                                    <td colSpan={10}>
                                                        <LoadingSkeleton />
                                                    </td>
                                                </tr>
                                            ) : deposits.length > 0 ? (
                                                deposits.map((deposit, index) => (
                                                    <tr key={index}>
                                                        <td data-title="#">{index + 1}</td>
                                                        <td data-title="User">{deposit.customerData.userName}</td>
                                                        <td data-title="Doc">{masks.cpf(deposit.customerData.document)}</td>
                                                        <td data-title="Cod.">{encodeBase64((deposit.id * 1000).toString().replace("=", ""))}</td>
                                                        <td data-title="PayMet">{deposit.payinMethodData.name}</td>
                                                        <td data-title="Value">{masks.brlCurrency(deposit.value)}</td>
                                                        <td data-title="ValuePaid">{masks.brlCurrency(deposit.valuePaid)}</td>
                                                        <td data-title="Fee">{masks.brlCurrency(deposit.fee)}</td>
                                                        <td data-title="Status">
                                                            <span className={`fs-6 badge badge-${status[deposit.status].color}`}>
                                                                {status[deposit.status].text}
                                                            </span>
                                                        </td>
                                                        <td data-title="PayDate">{deposit.datePay ? dayjs(deposit.datePay).format('DD/MM/YYYY HH:mm:ss') : '~'}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10} className="text-center">Nenhum depósito encontrado</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Pagination page={page} setPage={setPage} totalPages={totalPages} maxPageShow={3} />
                    </div>
                </Card>
            </div>
        </div>
    );
}
