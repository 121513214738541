import { Card } from "@components/cards/card";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { ILogsAdminDTO } from "@hooks/dtos/ILogsDto";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

export function LogsAdmin() {
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [logsAdmin, setLogsAdmin] = useState<ILogsAdminDTO[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { actions: { pushToastify } } = ZuToastifyStore()

    async function historicLogsAdmin(page: number) {
        try {
            const response = await api.post(`/manager/logs/list`, {
                category: "manager",
                page: page
            });
            setLogsAdmin(response.data.result);
            setTotalPages(response.data.pages);

        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        historicLogsAdmin(page);
    }, [page]);

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Card className="card-round">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-10">
                                    <h4 className="card-title">Logs</h4>
                                    <p className="card-category">Lista de todos os log's do administrador</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id="basic-datatables_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                                    <div id="basic-datatables_length" className="dataTables_length">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr role="row">
                                                    <th scope="col" className="fs-6">#</th>
                                                    <th scope="col">Operação</th>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Descrição</th>
                                                    <th scope="col">IP</th>
                                                    <th scope="col">Agente</th>
                                                    <th scope="col">Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isLoading ? (
                                                    <tr>
                                                        <td colSpan={10}>
                                                            <LoadingSkeleton />
                                                        </td>
                                                    </tr>
                                                ) : logsAdmin.length > 0 ? (
                                                    logsAdmin.map((log, index) => (
                                                        <tr key={index}>
                                                            <td data-title="#">{log.id}</td>
                                                            <td data-title="Operation">{log.opName}</td>
                                                            <td data-title="User">{log.name}</td>
                                                            <td data-title="Description">{log?.description || "Sem descrição"}</td>
                                                            <td data-title="IP">{log.ip}</td>
                                                            <td data-title="agent">{log.agent}</td>
                                                            <td data-title="createdAt">{log.createdAt ? dayjs(log.createdAt).format('DD/MM/YYYY [ás] HH:mm:ss') : '~'}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan={10} className="text-center">Nenhum registro encontrado.</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <Pagination page={page} setPage={setPage} totalPages={totalPages} maxPageShow={3} />
                        </div>
                    </Card>
                </div >
            </div>
        </>
    );
}