export function encodeBase64(input: string): string {
  const base64Chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
  let result = ''

  for (let i = 0; i < input.length; i += 3) {
    const chunk = input.slice(i, i + 3)
    const binaryChunk = chunk
      .split('')
      .map((char) => char.charCodeAt(0).toString(2).padStart(8, '0'))
      .join('')

    for (let j = 0; j < binaryChunk.length; j += 6) {
      const base64Index = parseInt(binaryChunk.slice(j, j + 6), 2)
      result += base64Chars.charAt(base64Index)
    }
  }

  return result
}

export function decodeBase64(input: string): string {
  const base64Chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
  let result = ''

  for (let i = 0; i < input.length; i += 4) {
    const chunk = input.slice(i, i + 4)
    const binaryChunk = chunk
      .split('')
      .map((char) => {
        const base64Index = base64Chars.indexOf(char)
        return base64Index !== -1
          ? base64Index.toString(2).padStart(6, '0')
          : ''
      })
      .join('')

    for (let j = 0; j < binaryChunk.length; j += 8) {
      const byte = binaryChunk.slice(j, j + 8)
      result += String.fromCharCode(parseInt(byte, 2))
    }
  }

  return result
}
