
export default function NavbarBottom() {

    return (
        <>
            <footer className="footer">
                <div className="container-fluid d-flex justify-content-between">
                    <div className="copyright">
                        2024, feito com <i className="fa fa-heart heart text-danger"></i>
                        <a href="https://arkcode.com.br/">Arkcode</a>
                    </div>
                </div>
            </footer>
        </>
    )
}