import axios from 'axios'

export const api = axios.create({
	baseURL: 'https://system.yolopix.bet',
})

api.interceptors.request.use(
	async (config) => {

		const [dirtyTokenId, dirtyTokenCSRF] =
			await Promise.all([
				localStorage.getItem('@yolo:token-id'),
				localStorage.getItem('@yolo:token-csrf'),
			])

		const tokenName = String(dirtyTokenId || '')
			.replace('"', '')
			.replace('"', '')
		const tokenValue = String(dirtyTokenCSRF || '')
			.replace('"', '')
			.replace('"', '')

		if (tokenName && tokenValue) {
			config.headers['token-id'] = tokenName
			config.headers['token-csrf'] = tokenValue
		}

		config.headers['app-lang'] = 'pt-br'

		return config
	},
	async (error) => {
		return Promise.reject(error)
	},
)

api.interceptors.response.use(
	async (response) => {
		// console.log(response.headers['token-id'])
		// console.log(
		//   response.headers['token-csrf'],
		//   String(response.headers['token-csrf']).length,
		// )
		if (response.headers['token-id'] || response?.data?.token?.id) {
			localStorage.setItem(
				'@yolo:token-id',
				String(response.headers['token-id'] || response?.data?.token?.id),
			)
		}
		if (response.headers['token-csrf'] || response?.data?.token?.csrf) {
			localStorage.setItem(
				'@yolo:token-csrf',
				String(response.headers['token-csrf'] || response?.data?.token?.csrf),
			)
		}

		return response
	},
	async (error) => {
		console.log('error?.response?.data', error?.response?.data || error)
		// if (String(error?.response?.data?.errorCode || '').match('CSRFX')) {
		//   await Promise.all([
		//     AsyncStorage.removeItem('@beev:customer'),
		//     AsyncStorage.removeItem('@beev:customer-profile'),
		//     AsyncStorage.removeItem('@beev:token-id'),
		//     AsyncStorage.removeItem('@beev:token-csrf'),
		//   ])
		// }
		return Promise.reject(error)
	},
)