import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface IProps {
    count?: number;
    height?: any;
}

export const LoadingSkeleton = ({ count = 5, height = 25 }: IProps) => {
    return (
        <Skeleton count={count} height={height} borderRadius={5} />
    );
}



