import Default from "@assets/img/defaultAvatar.png";
import { Button } from "@components/buttons/button";
import { ConfirmButton } from "@components/buttons/confirm-button";
import { Card } from "@components/cards/card";
import { CardInfo } from "@components/cards/card-info";
import { ModalAddBalance } from "@components/modals/modal-add-balance";
import { ModalRemoveBalance } from "@components/modals/modal-remove-balance";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { SeparatedInfo } from "@components/typography/separeted-info";
import { useModalContext } from "@hooks/contexts/modal-context";
import { ICustomerInfo, ILog } from "@hooks/dtos/customer/ICustomerDTO";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { FaMinusCircle } from "react-icons/fa";
import { FaCircleXmark, FaMinus, FaPencil, FaPlus } from "react-icons/fa6";
import { useLocation } from "react-router-dom";

interface ResumeUserProps {
  id: number;
}

const status = {
  a: "Ativo",
  p: "Pendente",
  i: "Inativo",
  x: "Excluido",
};

const statusMocksBanishment = {
  a: "Banir",
  i: "Desbanir"
};

const masks = new Masks();
export function ResumeUser({ id }: ResumeUserProps) {
  const { actions: { pushToastify } } = ZuToastifyStore()

  const location = useLocation();
  const { changeHash } = useModalContext();

  const [customerInfo, setCustomerInfo] = useState<ICustomerInfo>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [logs, setLogs] = useState<ILog[]>([]);
  const [statusCustomer, setStatusCustomer] = useState<"a" | "i">("a");

  const [blockedWithdraw, setBlockedWithdraw] = useState<"y" | "n">("n");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTable, setIsLoadingTable] = useState(true);

  async function getCustomerInfo() {
    try {
      const result = await api.post('/manager/customer/info', { customerId: id });

      setCustomerInfo(result.data);
      setStatusCustomer(result?.data?.customer?.status);
      setBlockedWithdraw(result?.data?.customer?.blackListData?.withdraw);
    } catch (err: any) {
      pushToastify({
        message: err?.response?.data.message ?? "",
        type: 'error',
        theme: 'dark',
      });
    }
    setIsLoading(false);
  }

  async function getCustomerLogs(page: number = 1) {
    try {
      const result = await api.post('/manager/customer/logs', { customerId: id, page });
      setLogs(result.data.result);
      setTotalPages(result.data.pages);
    } catch (err: any) {
      pushToastify({
        message: err?.response?.data.message ?? "",
        type: 'error',
        theme: 'dark',
      });
    }
    setIsLoadingTable(false);
  }

  async function handleBanishmentCustomer(otp: string = "", pin: string = "") {
    try {
      await api.post("/manager/banishment/customer", {
        customerId: customerInfo?.encryptedId,
        type: customerInfo?.customer?.status == "i" ? "a" : "i",
        otpAuth: otp,
        pin
      })

      pushToastify({
        message: "Sucesso! Ao banir o usuário!",
        type: 'success',
        theme: 'dark',
      });

      setTimeout(() => {
        window.location.reload();
      }, 1500)
    } catch (err: any) {
      pushToastify({
        message: err?.response?.data.message ?? "",
        type: 'error',
        theme: 'dark',
      });
    }
  }

  async function handleBlackListUser(otp: string = "", pin: string = "") {
    try {
      await api.post("/manager/blocked/customer", {
        customer: customerInfo?.customer?.id,
        withdraw: blockedWithdraw == "y" ? "n" : "y",
        payment: "n",
        otpAuth: otp,
        pin
      })

      pushToastify({
        message: "Sucesso! Ao bloquear saque do usuário!",
        type: 'success',
        theme: 'dark',
      });

      setTimeout(() => {
        window.location.reload();
      }, 1500)
    } catch (err: any) {
      pushToastify({
        message: err?.response?.data.message ?? "",
        type: 'error',
        theme: 'dark',
      });
    }
  }

  function getDocumentType(documentType?: number): string {
    switch (documentType) {
      case 1:
        return "CPF";
      case 2:
        return "CNPJ";
      default:
        return "";
    }
  }

  function getStatus(statusKey?: string): string {
    if (statusKey && status.hasOwnProperty(statusKey)) {
      return status[statusKey as keyof typeof status];
    }
    return "";
  }
  
  useEffect(() => {
    setIsLoadingTable(true);
    getCustomerLogs(page);
  }, [page]);

  useEffect(() => {
    getCustomerInfo();
    getCustomerLogs();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <Card>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 text-center">
                  <div className="avatar avatar-xxl mb-1">
                    <img src={customerInfo?.customer?.photos ?? Default} alt="" className="avatar-img rounded-circle" />
                  </div>
                  <h2>{customerInfo?.customer.name}</h2>
                </div>
              </div>

              <div className="row mt-2 gap-2 gap-md-0">
                <div className="col-md-6">
                  <Button onClick={() => changeHash('#addBalance')}
                    className="btn btn-success w-100 d-flex align-items-center justify-content-center gap-1">
                    <FaPlus />
                    Adicionar Saldo
                  </Button>
                </div>

                <div className="col-md-6">
                  <Button onClick={() => changeHash('#removeBalance')}
                    className="btn btn-danger w-100 d-flex align-items-center justify-content-center gap-1">
                    <FaMinus />
                    Remover Saldo
                  </Button>
                </div>
              </div>

              <div className="row mt-2 gap-2 gap-md-0">
                <div className="col-md-4">
                  <a href={`/user/edit/${id}`} className="btn btn-primary w-100 d-flex align-items-center justify-content-center gap-1">
                    <FaPencil />
                    Editar
                  </a>
                </div>

                <div className="col-md-4">
                  <ConfirmButton
                    validations="doubleCheck"
                    className={`btn  ${blockedWithdraw == "y" ? "btn-info" : "btn-danger"} w-100 d-flex align-items-center justify-content-center gap-1`}
                    title={`${blockedWithdraw == "y" ? "Desbloquear" : "Bloquear"}`}
                    textConfirmation="Por favor, confirme sua Senha, OTP para efetuar o bloqueio deste usuário!"
                    needConfirm={true}
                    onConfirm={(e, data) => {
                      if (e == true) {
                        handleBlackListUser(data?.otp, data?.pin)
                      }
                    }}
                  >
                    <FaMinusCircle />
                  </ConfirmButton>
                </div>

                <div className="col-md-4">
                  <ConfirmButton
                    validations="doubleCheck"
                    className={`btn ${statusCustomer == "a" ? "btn-danger" : "btn-info"}  w-100 d-flex align-items-center justify-content-center gap-1`}
                    title={statusMocksBanishment[statusCustomer]}
                    textConfirmation="Por favor, confirme sua Senha, OTP para efetuar o banimento deste usuário!"
                    needConfirm={true}
                    onConfirm={(e, data) => {
                      if (e == true) {
                        handleBanishmentCustomer(data?.otp, data?.pin)
                      }
                    }}
                  >
                    <FaCircleXmark />
                  </ConfirmButton>
                </div>
              </div>
            </div>
          </Card>

          <Card>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 border-bottom">
                  <h5 className="fw-bold">Informações Pessoais</h5>
                </div>
                {isLoading ? (
                  <LoadingSkeleton count={9} />
                ) : (
                  <div className="col-md-12 d-flex flex-column gap-2 mt-2">
                    <SeparatedInfo title="ID" value={customerInfo?.customer?.id ?? ''} />
                    <SeparatedInfo title="Username" value={customerInfo?.customer?.userName ?? ''} />
                    <SeparatedInfo title="Email" value={customerInfo?.customer?.email ?? ''} />
                    <SeparatedInfo title="Tipo de Documento" value={getDocumentType(customerInfo?.customer?.documentType)} />
                    <SeparatedInfo title="Documento" value={masks.cpf(customerInfo?.customer?.document ?? '')} />
                    <SeparatedInfo title="Status" value={getStatus(customerInfo?.customer?.status)} />
                    <SeparatedInfo title="Registrado em" value={customerInfo?.customer?.createdAt ? dayjs(customerInfo?.customer?.createdAt).format('DD-MM-YYYY  [ás] HH:mm:ss') : '00/00/0000 ás 00:00:00'} />
                    <SeparatedInfo title="Última Atualização" value={customerInfo?.customer?.updatedAt ? dayjs(customerInfo?.customer?.updatedAt).format('DD-MM-YYYY [ás] HH:mm:ss') : '00/00/0000 ás 00:00:00'} />
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>

        <div className="col-md-8">
          <div className="row">
            <div className="col-md-4">
              <Card>
                <div className="card-body">
                  <CardInfo
                    isLoading={isLoading}
                    title="Saldo"
                    subtitle="Este é o saldo atual do usuário no sistema."
                    value={masks.brlCurrency(customerInfo?.balance)}
                  />
                </div>
              </Card>
            </div>

            <div className="col-md-4">
              <Card>
                <div className="card-body">
                  <CardInfo
                    isLoading={isLoading}
                    title="Depósitos"
                    subtitle="Total em R$ já depositado por este usuário."
                    value={masks.brlCurrency(customerInfo?.totalDeposits)}
                  />
                </div>
              </Card>
            </div>

            <div className="col-md-4">
              <Card>
                <div className="card-body">
                  <CardInfo
                    isLoading={isLoading}
                    title="Retiradas"
                    subtitle="Total em R$ já sacado por este usuário."
                    value={masks.brlCurrency(customerInfo?.totalWithdraws)}
                  />
                </div>
              </Card>
            </div>

            <div className="col-md-4">
              <Card>
                <div className="card-body">
                  <CardInfo
                    isLoading={isLoading}
                    title="Apostas"
                    subtitle="Total em R$ já apostado por este usuário."
                    value={masks.brlCurrency(customerInfo?.bets?.total_bet)}
                  />
                </div>
              </Card>
            </div>

            <div className="col-md-4">
              <Card>
                <div className="card-body">
                  <CardInfo
                    isLoading={isLoading}
                    title="Bonificações"
                    subtitle="Total em R$ já ganho em bonificações por este usuário."
                    value={masks.brlCurrency(0)}
                  />
                </div>
              </Card>
            </div>

            <div className="col-md-4">
              <Card>
                <div className="card-body">
                  <CardInfo
                    isLoading={isLoading}
                    title="Indicações"
                    subtitle="Total de indicações diretas deste usuário."
                    value={customerInfo?.referrals ?? 0}
                  />
                </div>
              </Card>
            </div>

            <div className="col-md-6">
              <Card>
                <div className="card-body">
                  <CardInfo
                    isLoading={isLoading}
                    title="Total de ganhos"
                    subtitle="Total em R$ ganho em apostas por este usuário."
                    value={masks.brlCurrency(customerInfo?.bets?.total_won)}
                  />
                </div>
              </Card>
            </div>

            <div className="col-md-6">
              <Card>
                <div className="card-body">
                  <CardInfo
                    isLoading={isLoading}
                    title="Total de perdas"
                    subtitle="Total em R$ perdido em apostas por este usuário."
                    value={masks.brlCurrency(customerInfo?.bets?.total_loss)}
                  />
                </div>
              </Card>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <Card>
            <div className="card-header">
              <div className="card-title">Log's</div>
              <div className="card-category">Logs gerais das ações do usuário no sistema.</div>
            </div>
            <div className="card-body">
              <>
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Descrição</th>
                        <th>Navegador</th>
                        <th>Plataforma</th>
                        <th>IP</th>
                        <th>Efetuado</th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoadingTable ? (
                        <tr>
                          <td colSpan={10}>
                            <LoadingSkeleton />
                          </td>
                        </tr>
                      ) : logs.length > 0 ? (
                        logs.map((log, index) => (
                          <tr key={log.id}>
                            <th scope="row" className="text-start">{log.id}</th>
                            <td className="text-start">{log.opData.description}</td>
                            <td className="text-start">{log.browser}</td>
                            <td className="text-start">{log.platform}</td>
                            <td className="text-start">{log.ip}</td>
                            <td className="text-start">{dayjs(log.createdAt).format('DD/MM/YYYY [ás] HH:mm:ss')}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10} className="text-center">Nenhum registro encontrado</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <Pagination setPage={setPage} page={page} totalPages={totalPages} maxPageShow={2} />
              </>
            </div>
          </Card>
        </div>
      </div>

      <ModalAddBalance isOpen={location?.hash === '#addBalance'} onClose={() => changeHash('')} customerId={customerInfo?.encryptedId} />
      <ModalRemoveBalance isOpen={location?.hash === '#removeBalance'} onClose={() => changeHash('')} customerId={customerInfo?.encryptedId} />
    </>
  )
}
