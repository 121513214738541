import { Button } from '@components/buttons/button';
import React, { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

interface IImageBannerUploaderProps {
    width: number;
    height: number;
    label?: string;
    register?: any;
    showLabel?: boolean;
    errorMessage?: string;
    className?: string;
    onBase64Change?: (base64: string) => void;
    base64Value?: string;
}

export function ImageBannerUploader({
    width,
    height,
    label,
    register,
    showLabel = true,
    errorMessage,
    className = 'mb-3',
    onBase64Change,
    base64Value
}: IImageBannerUploaderProps) {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [error, setError] = useState<string>('');
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const img = new Image();
            const reader = new FileReader();

            reader.onloadend = async () => {
                img.src = reader.result as string;
                img.onload = () => {
                    const imgWidth = img.width;
                    const imgHeight = img.height;
                    if (imgWidth !== width || imgHeight !== height) {
                        setError(`A imagem deve ter as dimensões ${width}x${height}px.`);
                        return;
                    }
                    
                    setError('');
                    const imgBase64 = reader.result as string;
                    if (onBase64Change) {
                        onBase64Change(imgBase64);
                    }

                    const objectUrl = URL.createObjectURL(file);
                    setImageSrc(objectUrl);
                };
            };

            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setImageSrc(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    useEffect(() => {
        if (!base64Value && fileInputRef.current) {
            fileInputRef.current.value = ``;
        }
    }, [base64Value]);

    return (
        <div className={className}>
            {showLabel && label && <label className="form-label">{label}</label>}
            <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="form-control"
                ref={fileInputRef}
            />
            {error && <p className="text-danger">{error}</p>}
            <div className="row">
                <div className="text-center">
                    <div className="badge badge-info mt-3 mb-2">
                        <span className="p-2">Pré-visualização</span>
                    </div>
                    <div>
                        {base64Value ? (
                            <div>
                                <img
                                    src={base64Value}
                                    alt="Preview"
                                    {...register}
                                    style={{
                                        maxWidth: '100%',
                                        height: 'auto',
                                        border: '1px solid #ddd',
                                        backgroundColor: '#f0f0f0'
                                    }}
                                />
                                <div>
                                    <Button className="btn btn-warning mt-2" onClick={handleRemoveImage}>
                                        Remover
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <Skeleton count={1} height={150} borderRadius={5} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
