import background from "@assets/img/auth/background.png";
import cat from "@assets/img/auth/cat.png";
import logo from "@assets/img/auth/logo.png";
import { Button } from "@components/buttons/button";
import { Input } from "@components/forms/input";
import { InputGroup } from "@components/forms/input-group";
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuthContext } from '@hooks/contexts/auth-context';
import { useEffect } from "react";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface IFormValues {
  userName: string;
  password: string;
  termsCheck: boolean;
}

export function Auth() {
  const scheme = yup.object().shape({
    userName: yup
      .string()
      .required('E-mail é obrigatório'),
    password: yup
      .string()
      .required('Senha é obrigatório'),
  });

  const { register, reset, handleSubmit, formState: { errors } } = useForm<IFormValues>({
    resolver: yupResolver(scheme) as any,
    mode: 'onSubmit',
    reValidateMode: 'onBlur'
  });

  const { signIn } = useAuthContext();

  async function handleLogin(data: IFormValues) {
    await signIn(data);
  }

  function handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      const activeElement = document.activeElement;
      if (activeElement instanceof HTMLInputElement || activeElement instanceof HTMLTextAreaElement) {
        activeElement.blur();
      }
      event.preventDefault();
      handleSubmit(handleLogin)();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="row vh-100">
          <div className="col-md-7"
            style={{
              backgroundImage: `url(${cat})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}></div>


          <div className="col-md-5 gap-4"
            style={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundColor: '#000',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100vh',
              padding: '3rem'
            }}>

            <div className="row gap-3">
              <div className="col-md-12 text-center">
                <img src={logo} alt="Logo" className="img-fluid" />
              </div>
              <div className="col-md-12 text-center">
                <h4 className="text-white">
                  Seja bem-vindo novamente, entre com sua conta de administrador.
                </h4>
              </div>
            </div>

            <form onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(handleLogin)();
            }}>
              <div className="row">
                <div className="col-md-12">
                  <Input
                    label="Nome de usuário"
                    placeholder="Digite seu nome de usuário"
                    showLabel={false}
                    type="text"
                    register={register('userName')}
                    errorMessage={errors?.userName?.message}
                  />
                </div>

                <div className="col-md-12">
                  <InputGroup
                    label="Senha"
                    placeholder="*********"
                    type="password"
                    register={register('password')}
                    errorMessage={errors?.password?.message}
                  />
                </div>

                <div className="col-md-12 mb-2 col-12">
                  <Button children="Entrar" className="btn btn-purple w-100 py-3" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}