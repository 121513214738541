/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

import { useEffect, useState } from 'react';
interface IProps {
    loading?: boolean;
    setPage: (page: number) => void;
    page: number;
    totalPages: number;
    maxPageShow: number;
}

export function Pagination({ loading, setPage, page, totalPages, maxPageShow }: IProps) {

    const [arrPages, setArrPages] = useState<number[]>([])
    const [showPages, setShowPages] = useState<number[]>([])

    useEffect(() => {
        if(totalPages > 0) {
            setArrPages(Array.from({ length: totalPages }, (_, index) => index + 1));
        }
    }, [totalPages]);


    useEffect(() => {
        setPage(1);
        setShowPages(arrPages.slice(0, maxPageShow));
    }, [arrPages])


    useEffect(() => {
        const firstMiddle = Math.floor((1 + maxPageShow) / 2);
        const lastMiddle = (totalPages - Math.floor(maxPageShow / 2));

        if(page > firstMiddle && page < lastMiddle) {
            setShowPages(arrPages.slice((page - 1) - Math.floor(maxPageShow / 2), page + Math.floor(maxPageShow / 2)));
        } else if(page <= firstMiddle) {
            setShowPages(arrPages.slice(0, maxPageShow));
        } else if(page >= lastMiddle) {
            setShowPages(arrPages.slice((lastMiddle - 1) - Math.floor(maxPageShow / 2), lastMiddle + Math.floor(maxPageShow / 2)));
        }

    }, [page]);

    return (
        <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-end mb-0">


                <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                    <button type="button" className="page-link" onClick={() => {
                        if(page > 1) {
                            setPage(page - 1);
                        }
                    }}>
                        <FaChevronLeft />
                    </button>
                </li>

                {showPages.map(number => (
                    <li key={number} className={`page-item ${number === page? 'active' : ''}`} onClick={() => setPage(number)}>
                        <button type="button" className="page-link">{number}</button>
                    </li>
                ))}

                <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                    <button type="button" className="page-link" onClick={() => {
                        if(page < totalPages) {
                            setPage(page + 1);
                        }
                    }}>
                        <FaChevronRight />
                    </button>
                </li>
            </ul>
        </nav>
    )
}