import { useParams } from "react-router-dom";
import { Historical } from "./sub-pages/Historical";
import { ResumeUser } from "./sub-pages/Resume";

export function UserInfo() {
  const { id } = useParams();

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <ul className="nav nav-pills nav-secondary" id="pills-tab" role="tablist">
            <li className="nav-item submenu " role="presentation">
              {/* bg-white */}
              <a className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Resumo</a>
            </li>
            <li className="nav-item submenu" role="presentation">
              <a className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false" >Histórico</a>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="tab-content mt-2 mb-3" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <ResumeUser id={Number(id)} />
            </div>
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
              <Historical id={Number(id)} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}