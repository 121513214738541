import { Card } from "@components/cards/card";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IBetsHistorical, IDepositHistorical, IWithdrawsHistorical } from "@hooks/dtos/customer/ICustomerDTO";
import { encodeBase64 } from "@hooks/functions/base64";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";

import { api } from "@service/api";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface HistoricalProps {
  id: number;
}

const statusDeposit = {
  w: {
    color: "warning",
    text: "Pendente",
  },
  p: {
    color: "success",
    text: "Pago"
  },
  h: {
    color: "danger",
    text: "Deletado",
  },
  x: {
    color: "danger",
    text: "Cancelado",
  },
}

const statusWithdraw = {
  s: {
    color: "success",
    text: "Enviado"
  },
  p: {
    color: "success",
    text: "Aprovado",
  },
  w: {
    color: "warning",
    text: "Pendente",
  },
  x: {
    color: "danger",
    text: "Cancelado",
  },
}

const statusBets = {
  p: {
    color: "info",
    text: "Pendente",
  },
  w: {
    color: "success",
    text: "Ganho"
  },
  hw: {
    color: "success",
    text: "Ganho parcial"
  },
  l: {
    color: "warning",
    text: "Perca"
  },
  hl: {
    color: "warning",
    text: "Perca parcial"
  },
  x: {
    color: "danger",
    text: "Cancelado",
  },
}
const masks = new Masks();
export function Historical({ id }: HistoricalProps) {
  type Category = keyof typeof descriptions;
  const descriptions: Record<'deposits' | 'withdraws' | 'bets', string> = {
    deposits: `Lista de depósitos do usuário ${id}.`,
    withdraws: `Lista de saques do usuário ${id}.`,
    bets: `Lista de apostas do usuário ${id}.`,
  };

  const [category, setCategory] = useState<Category>('deposits');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [deposits, setDeposits] = useState<IDepositHistorical>();
  const [withdraws, setWithdraws] = useState<IWithdrawsHistorical>();
  const [bets, setBets] = useState<IBetsHistorical>();

  const [description, setDescription] = useState<string>(descriptions[category]);
  const [isLoading, setIsLoading] = useState(true);

  const { actions: { pushToastify } } = ZuToastifyStore()

  async function getHistorical(page: number) {
    try {
      let data;
      if (category === 'deposits') data = deposits;
      if (category === 'withdraws') data = withdraws;
      if (category === 'bets') data = bets;

      if (page > 1 || data === undefined || data?.currentPage != 1) {
        const result = await api.post("/manager/customer/historicals", { customerId: id, page, category });

        result.data.currentPage = page;
        if (category === 'deposits') setDeposits(result.data);
        if (category === 'withdraws') setWithdraws(result.data);
        if (category === 'bets') setBets(result.data);

        setTotalPages(result.data.pages);

      } else {
        if (category === 'deposits') setTotalPages(data.pages);
        if (category === 'withdraws') setTotalPages(data.pages);
        if (category === 'bets') setTotalPages(data.pages);
      }
    } catch (err: any) {
      pushToastify({
        message: err?.response?.data.message ?? "Ocorreu um erro!",
        type: 'error',
        theme: 'dark',
      });
    }

    setIsLoading(false);
  }


  function getCategory(category: Category) {
    setDescription(descriptions[category as Category]);
    setCategory(category);
  }

  useEffect(() => {
    setIsLoading(true);
    getHistorical(page);
  }, [page])

  useEffect(() => {
    setIsLoading(true);
    getHistorical(1);
  }, [category])

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <Card>
            <div className="card-header">
              <div className="row">
                <div className="col-md-10">
                  <ul className="nav nav-pills nav-secondary" id="pills-tab" role="tablist">
                    <li className="nav-item submenu " role="presentation">
                      <a className={`nav-link ${category === "deposits" ? "active" : ""}`} id="pills-deposits-tab" onClick={() => getCategory("deposits")} data-bs-toggle="pill" href="#pills-deposits" role="tab" aria-controls="pills-deposits" aria-selected={category === "deposits"}>Depósitos</a>
                    </li>
                    <li className="nav-item submenu" role="presentation">
                      <a className={`nav-link ${category === "withdraws" ? "active" : ""}`} id="pills-withdraws-tab" onClick={() => getCategory("withdraws")} data-bs-toggle="pill" href="#pills-withdraws" role="tab" aria-controls="pills-withdraws" aria-selected={category === "withdraws"}>Retiradas</a>
                    </li>
                    <li className="nav-item submenu" role="presentation">
                      <a className={`nav-link ${category === "bets" ? "active" : ""}`} id="pills-bets-tab" onClick={() => getCategory("bets")} data-bs-toggle="pill" href="#pills-bets" role="tab" aria-controls="pills-bets" aria-selected={category === "bets"}>Apostas</a>
                    </li>
                  </ul>
                  <div className="card-category">{description}</div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      {category === "deposits" &&
                        <>
                          <th>Tr Id</th>
                          <th>Método</th>
                          <th>Status</th>
                          <th>Valor</th>
                          <th>Taxa</th>
                          <th>Pago</th>
                          <th>Data de pagamento</th>
                        </>
                      }
                      {category === "withdraws" &&
                        <>
                          <th>Tr Id</th>
                          <th>Chave pix</th>
                          <th>Método</th>
                          <th>Status</th>
                          <th>Valor</th>
                          <th>Taxa</th>
                          <th>Data de criação</th>
                        </>
                      }
                      {category === "bets" &&
                        <>
                          <th>Game</th>
                          <th>Round</th>
                          <th>Aposta</th>
                          <th>Saldo</th>
                          <th>Profit</th>
                          <th>Taxa da casa</th>
                          <th>Taxa do Provedor</th>
                          <th>Status</th>
                          <th>Data da aposta</th>
                        </>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={10} className="text-center">
                          <LoadingSkeleton />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {category === "deposits" && (
                          deposits?.result && deposits.result.length > 0 ? ( deposits.result.map((deposit) => (
                              <tr key={deposit.id}>
                                <th scope="row" className="text-start">{deposit.id}</th>
                                <td className="text-start">{encodeBase64((deposit.id * 1000).toString().replace("=", ""))}</td>
                                <td className="text-start">{deposit.payinMethodData.name}</td>
                                <td className="text-start">
                                  <span className={`fs-6 badge badge-${statusDeposit[deposit.status].color}`}>
                                    {statusDeposit[deposit.status].text}
                                  </span>
                                </td>
                                <td className="text-start">{masks.brlCurrency(deposit.fee)}</td>
                                <td className="text-start">{masks.brlCurrency(deposit.value)}</td>
                                <td className="text-start">{masks.brlCurrency(deposit.valuePaid)}</td>
                                <td className="text-start">{deposit.datePay ? dayjs(deposit.datePay).format('DD/MM/YYYY [ás] HH:mm:ss') : '00/00/00 ás 00:00:00'}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8} className="text-center">Nenhum registro encontrado.</td>
                            </tr>
                          )
                        )}

                        {category === "withdraws" && (
                          withdraws?.result && withdraws.result.length > 0 ? ( withdraws.result.map((withdraw) => (
                              <tr key={withdraw.id}>
                                <th scope="row" className="text-start">{withdraw.id}</th>
                                <td className="text-start">{withdraw.payoutId}</td>
                                <td className="text-start">{JSON.parse(withdraw.accountData).key}</td>
                                <td className="text-start">{withdraw.payoutMethodData.name}</td>
                                <td className="text-start">
                                  <span className={`fs-6 badge badge-${statusWithdraw[withdraw.status].color}`}>
                                    {statusWithdraw[withdraw.status].text}
                                  </span>
                                </td>
                                <td className="text-start">{masks.brlCurrency(withdraw.value)}</td>
                                <td className="text-start">{masks.brlCurrency(withdraw.fee)}</td>
                                <td className="text-start">{withdraw.dateRequest ? dayjs(withdraw.dateRequest).format('DD/MM/YYYY [ás] HH:mm:ss') : '00/00/00 ás 00:00:00'}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8} className="text-center">Nenhum registro encontrado.</td>
                            </tr>
                          )
                        )}

                        {category === "bets" && (
                          bets?.result && bets.result.length > 0 ? ( bets.result.map((bet) => (
                              <tr key={bet.id}>
                                <th scope="row" className="text-start">{bet.id}</th>
                                <td className="text-start">{bet.gameData.name}</td>
                                <td className="text-start">{bet.round}</td>
                                <td className="text-start">{masks.brlCurrency(bet.amount)}</td>
                                <td className="text-start">{masks.brlCurrency(bet.balance)}</td>
                                <td className="text-start">{masks.brlCurrency(bet.profit)}</td>
                                <td className="text-start">{masks.brlCurrency(bet.houseFee)}</td>
                                <td className="text-start">{masks.brlCurrency(bet.providerFee)}</td>
                                <td className="text-start">
                                  <span className={`fs-6 badge badge-${statusBets[bet.status].color}`}>
                                    {statusBets[bet.status].text}
                                  </span>
                                </td>
                                <td className="text-start">{dayjs(bet.createdAt).format('DD/MM/YYYY [ás] HH:mm:ss')}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={10} className="text-center">Nenhum registro encontrado.</td>
                            </tr>
                          )
                        )}
                      </>
                    )}
                  </tbody>

                </table>
                <Pagination setPage={setPage} page={page} totalPages={totalPages} maxPageShow={2} />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}
