import { Masks } from "@hooks/functions/Masks/Masks";
import React, { useState } from "react";

import { FiEye, FiEyeOff } from "react-icons/fi";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    animated?: boolean;
    mask?: "phone" | "cpf" | "cnpj" | "cep" | "data";
    register?: any;
    icon?: any;
    errorMessage?: string;
}

const masks = new Masks();

export function InputGroupProfile({
    label,
    animated,
    register,
    mask,
    type,
    placeholder,
    icon,
    errorMessage,
    onChange,
    ...rest
}: IProps) {
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value;

        if (mask && maskObj[mask]) {
            newValue = maskObj[mask](newValue);
        }

        e.target.value = newValue;

        if (onChange) {
            onChange(e);
        }
    };

    const maskObj = {
        phone: masks.phone,
        cpf: masks.cpf,
        cnpj: masks.cnpj,
        cep: masks.postalCode,
        data: masks.date,
    };

    return (
        <>
            {animated ? (
                <div className="input-group mb-3">
                    <div className="form-floating flex-grow-1">
                        <input
                            className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                            placeholder={placeholder}
                            type={type === 'password' && showPassword ? 'text' : type ?? 'text'}
                            {...register}
                            {...rest}
                            onChange={handleChange}
                        />
                        <label>{label}</label>
                    </div>
                    {type === 'password' && (
                        <button
                            type="button"
                            className="border-0 btn btn-white secondary"
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}
                        >
                            {showPassword ? <FiEye /> : <FiEyeOff />}
                        </button>
                    )}
                    {type !== 'password' && (
                        <span className="input-group-text">
                            {icon}
                        </span>
                    )}
                    {errorMessage && (
                        <div className="invalid-feedback">
                            {errorMessage}
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    {label && <label className="form-label text-white">{label}</label>}
                    <div className={`input-group mb-3 input-${type}`}>
                        <input
                            className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                            placeholder={placeholder}
                            type={type === 'password' && showPassword ? 'text' : type ?? 'text'}
                            {...register}
                            {...rest}
                            onChange={handleChange}
                        />
                        {type === 'password' && (
                            <button
                                type="button"
                                className="border-0 btn btn-white"
                                onClick={() => {
                                    setShowPassword(!showPassword);
                                }}
                            >
                                {showPassword ? <FiEye /> : <FiEyeOff />}
                            </button>
                        )}
                        {errorMessage && (
                            <div className="invalid-feedback">
                                {errorMessage}
                            </div>
                        )}
                        {type !== 'password' && (
                            <span className="input-group-text">
                                {icon}
                            </span>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}
