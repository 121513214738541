/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    placeholder?: string;
    animated?: boolean;
    register?: any;
    prefix?: string;
    sufix?: string;
    decimal?: string;
    floats?: number;
    thousands?: string;
    errorMessage?: string;
    value?: number;
    onChangeTextOut?: (v: number, valueFormat?: string) => void
}

export function InputCurrency({ label, prefix = '', sufix = '', decimal = ',', floats = 2, thousands = '.', placeholder, animated, register, errorMessage, value, onChangeTextOut, ...rest }: IProps) {

    const [input, setInput] = useState('')
    const [numValue, setNumValue] = useState('0')
    
    function convertStringToNumber(value: string): string {
        value = value.replace(/[^0-9.,]/g, '')
        if (value.startsWith(`0${decimal}`)) {
            value = value.replace(`0${decimal}`, '')
        }

        const array = value.split('').reverse()

        if (array.length < 1) {
            setNumValue('0')
            return '0'
        }
        const replace = []
        if (array.length <= floats) {
            array.push(...''.padEnd(floats + 1 - array.length, '0').split(''))
        }
        for (let index = 0; index < array.length; index++) {
            const element = array[index]
            if (element.match(/[0-9]/g)) {
                replace.unshift(element)
            }
            if (index > 0 && index === floats - 1) {
                replace.unshift('.')
            }
        }

        const numericValue = replace.join('')
        setNumValue(numericValue)
        return numericValue
    }

    function applyMask(value: string): string {
        if (!value || Number(value) === 0) {
            setInput('')
            if(onChangeTextOut) {
                onChangeTextOut(Number(0), '')
            }
            return ''
        }
        const [int, decimals] = value.toString().split('.')
        const intRev = int.split('').reverse()
        const replace = []

        let addThousands = 0
        for (let index = 0; index < intRev.length; index++) {
            const element = intRev[index]
            replace.unshift(element)
            if (index > 0 && index + (1 % 3) === 0 && index + 1 < intRev.length) {
                replace.unshift(thousands || '.')
            }
            addThousands++

            if (addThousands === 3 && index + 1 < intRev.length) {
                replace.unshift(thousands || '')
                addThousands = 0
            }
        }
        const formatValue = `${prefix || ''} ${replace.join('')}${decimal}${!decimals ? ''.padEnd(floats, '0') : decimals}${sufix || ''}`
        setInput(formatValue)
        if(onChangeTextOut) {
            onChangeTextOut(Number(value), formatValue)
        }
        return formatValue
    }

    useEffect(() => {
        if (typeof value == "number" && value !== Number(numValue)) {
            setNumValue(String(value))
        }
    }, [value])

    useEffect(() => {
        applyMask(String(numValue))
    }, [numValue])

    return (
        <>
            {animated ? (
                <div className={`form-floating mb-3`}>
                    <input
                        className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                        type="tel"
                        {...register}
                        {...rest}
                        placeholder={placeholder || `${prefix || ''} 0${decimal}00`}
                        onChange={(e) => convertStringToNumber( e.target.value)}
                        value={input}
                    />
                    {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
                    <label>{label}</label>
                </div>
            ) : (
                <div>
                    {label && <label className="form-label">{label}</label>}
                    <div className={`mb-3`}>
                        <input
                            className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                            type="tel"
                            {...register}
                            {...rest}
                            placeholder={placeholder || `${prefix || ''} 0${decimal}00`}
                            onChange={(e) => convertStringToNumber( e.target.value)}
                            value={input}
                        />
                        {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
                    </div>
                </div>
            )}
        </>
    );
}
