import { Card } from "@components/cards/card";
import { useEffect, useState } from "react";

import { api } from "@service/api";
import dayjs from "dayjs";

import { InputSearch } from "@components/forms/input-search";
import { Pagination } from "@components/pagination";
import { LoadingSkeleton } from "@components/skeleton-loading";
import { IWithdrawsPaymentDTO } from "@hooks/dtos/IWithdrawDto";
import { Masks } from "@hooks/functions/Masks/Masks";
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";

const status = {
    s: {
        color: "success",
        text: "Enviado"
    },
    p: {
        color: "success",
        text: "Aprovado",
    },
    w: {
        color: "warning",
        text: "Pendente",
    },
    x: {
        color: "danger",
        text: "Cancelado",
    },
}
const masks = new Masks();
export function Withdraw() {
    const [isLoading, setIsLoading] = useState(true);
    const { actions: { pushToastify } } = ZuToastifyStore()
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [withdraws, setWithdraws] = useState<IWithdrawsPaymentDTO[]>([]);
    const [search, setSearch] = useState('');

    async function historicDeposits(page: number, search: string = '') {
        try {
            const response = await api.post(`/manager/cash/payout/pagination`, {
                search: search,
                page: page,
            });
            setWithdraws(response.data.result);
            setTotalPages(response.data.pages);
        } catch (err: any) {
            pushToastify({
                message: err?.response?.data.message ?? "Ocorreu um erro!",
                type: 'error',
                theme: 'dark',
            });
        }
        setIsLoading(false);
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setSearch(e.target.value);
    };

    useEffect(() => {
        historicDeposits(page);
    }, [page]);

    useEffect(() => {
        setIsLoading(true);
        const delayDebounceFn = setTimeout(() => {
            setPage(1);
            historicDeposits(1, search);
        }, 2500);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    return (
        <div className="row">
            <div className="col-md-12">
                <Card className="card-round">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-10">
                                <h4 className="card-title">Saques</h4>
                                <p className="card-category">Lista de todos os saques</p>
                            </div>
                            <div className="col-md-2">
                                <InputSearch animated label="Buscar" placeholder="" value={search} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <div id="basic-datatables_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                                <div id="basic-datatables_length" className="dataTables_length">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr role="row">
                                                <th scope="col" className="fs-6">#</th>
                                                <th scope="col" className="fs-6">Usuário</th>
                                                <th scope="col" className="fs-6">Documento</th>
                                                <th scope="col" className="fs-6">Método</th>
                                                <th scope="col" className="fs-6">Cod. Saque</th>
                                                <th scope="col" className="fs-6">Valor</th>
                                                <th scope="col" className="fs-6">Taxa</th>
                                                <th scope="col" className="fs-6">Status</th>
                                                <th scope="col" className="fs-6">Requisição</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? (
                                                <tr>
                                                    <td colSpan={10}>
                                                        <LoadingSkeleton />
                                                    </td>
                                                </tr>
                                            ) : withdraws.length > 0 ? (
                                                withdraws.map((withdraw, index) => (
                                                    <tr key={index}>
                                                        <td data-title="#">{withdraw.id}</td>
                                                        <td data-title="User">{withdraw.customerData.userName}</td>
                                                        <td data-title="Doc">{masks.cpf(withdraw.customerData.document)}</td>
                                                        <td data-title="Method">{withdraw.payoutMethodData.name}</td>
                                                        <td data-title="PayoutId">{withdraw.payoutId}</td>
                                                        <td data-title="Value">{masks.brlCurrency(withdraw.value)}</td>
                                                        <td data-title="Fee">{masks.brlCurrency(withdraw.fee)}</td>
                                                        <td data-title="Status">
                                                            <span className={`fs-6 badge badge-${status[withdraw.status].color}`}>
                                                                {status[withdraw.status].text}
                                                            </span>
                                                        </td>
                                                        <td data-title="PayDate">{withdraw.dateRequest ? dayjs(withdraw.dateRequest).format('DD/MM/YYYY HH:mm:ss') : '~'}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan={10} className="text-center">Nenhum registro encontrado.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <Pagination page={page} setPage={setPage} totalPages={totalPages} maxPageShow={3} />
                    </div>
                </Card>
            </div>
        </div>
    );
}
