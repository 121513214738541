import { Masks } from "@hooks/functions/Masks/Masks";
import React, { useState } from "react";

import { FiEye, FiEyeOff } from "react-icons/fi";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    showLabel?: boolean;
    animated?: boolean;
    placeholder?: string;
    mask?: "phone" | "cpf" | "cnpj" | "cep" | "data";
    register?: any;
    errorMessage?: string
}

const masks = new Masks();

export function Input({ label, showLabel, placeholder, className = 'mb-3', animated, register, mask, type, errorMessage, ...rest }: IProps) {
    const [showPassword, setShowPassword] = useState(false);

    const maskObj = {
        "phone": masks.phone,
        "cpf": masks.cpf,
        "cnpj": masks.cnpj,
        "cep": masks.postalCode,
        "data": masks.date
    }

    return (
        <>
            {animated &&
                <div className={`form-floating ${className} input-${type} mb-3`}>
                    <input
                        className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                        placeholder={placeholder}
                        type={type === 'password' && showPassword ? 'text' : type ?? 'text'}
                        {...register}
                        {...rest}
                        onChange={
                            (e) => {
                                if (mask && maskObj[mask]) {
                                    e.target.value = maskObj[mask](e.target.value);
                                }
                            }
                        }
                    />
                    {errorMessage &&
                        <div className="invalid-feedback">
                            {errorMessage}
                        </div>
                    }

                    <label>{label}</label>
                    {
                        type === 'password' && (
                            <button
                                className="border-0"
                                onClick={() => {
                                    setShowPassword(!showPassword);
                                }}
                            >
                                {showPassword ? <FiEye /> : <FiEyeOff />}
                            </button>
                        )
                    }
                </div>
            }

            {!animated &&
                <div>
                    {label && <label className="form-label text-white">{label}</label>}

                    <div className={`${className} input-${type}`}>
                        <input
                            className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                            placeholder={placeholder}
                            type={type === 'password' && showPassword ? 'text' : type ?? 'text'}
                            {...register}
                            {...rest}
                            onChange={
                                (e) => {
                                    if (mask && maskObj[mask]) {
                                        e.target.value = maskObj[mask](e.target.value);
                                    }
                                }
                            }
                        />

                        {errorMessage &&
                            <div className="invalid-feedback">
                                {errorMessage}
                            </div>
                        }
                        {
                            type === 'password' && (
                                <button
                                    className="border-0"
                                    onClick={() => {
                                        setShowPassword(!showPassword);
                                    }}
                                >
                                    {showPassword ? <FiEye /> : <FiEyeOff />}
                                </button>
                            )
                        }
                    </div>
                </div>
            }
        </>
    );
}