
interface IProps {
    className?: string;
    children: any;
}

export function Card({ className, children }: IProps) {

    return (
        <div className={`card ${className}`}>
            {children}
        </div>
    )
}