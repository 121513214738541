import { Button } from "@components/buttons/button";
import { ConfirmButton } from "@components/buttons/confirm-button";
import { Card } from "@components/cards/card";
import { ModalAddBanner } from "@components/modals/modal-add-banner";
import { ModalEditBanner } from "@components/modals/modal-edit-banner";
import { useModalContext } from "@hooks/contexts/modal-context";
import { IBannerConfigDTO, IBannerDTO } from "@hooks/dtos/IBannerDTO";
import { ZuToastifyStore } from '@hooks/stores/zustand-toastify-store';
import { api } from "@service/api";
import { useEffect, useState } from "react";
import { FaPowerOff } from "react-icons/fa";
import { FaPencil } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";

export function Banner() {
    const location = useLocation();
    const { changeHash } = useModalContext();

    const [bannerConfig, setBannerConfig] = useState<IBannerConfigDTO>({});
    const [seeButton, setSeeButton] = useState<string>("a");
    const [seeButtonTxt, setSeeButtonTxt] = useState<string>("Mostrar");
    const [isLoading, setIsLoading] = useState(true);
    const [banner, setBanner] = useState<IBannerDTO>();
    const [banners, setBanners] = useState<IBannerDTO[]>([]);
    const { actions: { pushToastify } } = ZuToastifyStore();

    function OpenModal(banner: IBannerDTO) {
        setBanner(banner);
        changeHash("editBanner")
    }

    function addBannerModal(banners: IBannerDTO[]) {
        const bannersAmount = banners?.length;
        const bannersMax = (bannerConfig?.home?.max || 10);
        if (bannersAmount >= bannersMax) {
            pushToastify({
                message: `O limite máximo de Banners ativos para a Home Page é de ${bannersMax}`,
                type: 'error',
                theme: "dark",
            });
            return;
        }
        changeHash("addBanner")
    }

    async function clickSeeButton() {
        getBanners(seeButton === `a` ? `` : `a`);
        setSeeButton(seeButton === `a` ? `` : `a`);
        setSeeButtonTxt(seeButton === `a` ? `Ocultar` : `Mostrar`);
    }

    async function getBanners(status: string = "a") {
        try {
            const result = await api.get(`/manager/customization/banner${status ? `?status=${status}` : ``}`);
            setBanners(result.data);
        } catch (err: any) {
            console.error(err);
        }
        setIsLoading(false);
    }

    async function getBannerConfigs() {
        try {
            const result = await api.get('/manager/customization/banner/config');
            const bannerConfigValue = result.data?.value;
            if (bannerConfigValue) {
                const configBanners = JSON.parse(bannerConfigValue)?.banner;
                if (configBanners) {
                    setBannerConfig(configBanners);
                }
                return;
            }

            pushToastify({
                message: "Houve um erro ao carregar as configurações de Banners do Sistema!",
                type: 'error',
                theme: "dark",
                pauseOnHover: true
            });
        } catch (err: any) {
            console.error(err);
        }
    }

    async function updateBanner(bannerId: number, status: "a" | "i") {
        try {
            await api.post('/manager/customization/banner/update', {bannerId, status});

            getBanners(seeButton);

            pushToastify({
                message: `O status do banner foi atualizado com sucesso.`,
                type: 'success',
                theme: "dark",
            });
        } catch (error: any) {
            console.error(error);
            pushToastify({
                message: error?.response?.data?.message,
                type: 'error',
                theme: "dark",
            });
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getBanners(seeButton);

        // --- Load the System Banner Configs --- //
        getBannerConfigs();
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <Card>
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="">
                                    <span className="fs-5">Lista de Banners</span>
                                </div>
                                <div className="d-flex gap-2">
                                    <Button className="btn btn-success" onClick={() => addBannerModal(banners)}>Adicionar</Button>
                                    <Button className="btn btn-primary" onClick={() => clickSeeButton()}>{seeButtonTxt} Inativos</Button>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>

            <div className="row">
                {(isLoading || (banners.length <= 0)) ? (
                    <>
                        <div className="col-md-4">
                            <Card>
                                <div className="card-body">
                                    <Skeleton count={5} />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card>
                                <div className="card-body">
                                    <Skeleton count={5} />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-4">
                            <Card>
                                <div className="card-body">
                                    <Skeleton count={5} />
                                </div>
                            </Card>
                        </div>
                    </>
                ) : (
                    banners.map((banner, index) => (
                        <div className="col-md-4" key={index}>
                            <Card>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="position-relative">
                                                <span className="fs-6 badge badge-info position-absolute m-2 top-0 start-0">{banner?.order}ª</span>
                                                <div className="position-absolute m-2 top-0 end-0">
                                                    <button className="btn me-2 text-warning p-0" onClick={() => OpenModal(banner)}><FaPencil /></button>
                                                    <ConfirmButton
                                                        title={<FaPowerOff />}
                                                        className={`btn me-2 text-${banner.status === "i" ? "success" : "danger"} p-0`}
                                                        textConfirmation={`Tem certeza que deseja ${banner.status === "i" ? "" : "des"}ativar este banner?`}
                                                        needConfirm={true}
                                                        onConfirm={(e, data) => {
                                                            if (e === true) {
                                                                updateBanner(banner?.id, (banner.status === "a" ? "i" : "a"));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <img src={`${banner.image}&size=lg`} alt="" className="rounded-2 img-fluid" style={{
                                                    cursor: 'pointer',
                                                    maxWidth: '100%',
                                                    height: 'auto',
                                                    border: '1px solid #ddd',
                                                    backgroundColor: '#f0f0f0'
                                                }} onClick={() => OpenModal(banner)} />
                                                <div className={`position-absolute bottom-0 bg-${banner.status === "a" ? "dark" : "danger"} w-100 opacity-75`}>
                                                    <span className="fs-6 text-white ms-2">{banner?.name ?? `Banner sem apelido`}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    ))
                )}
            </div>

            <ModalEditBanner
                banner={banner ?? {} as IBannerDTO}
                banners={banners ?? {} as IBannerDTO[]}
                isOpen={location?.hash === '#editBanner'}
                onClose={() => {
                    changeHash('');
                    setBanner(undefined);
                    getBanners(seeButton);
                }}
            />

            <ModalAddBanner
                bannerConfig={bannerConfig ?? {} as IBannerConfigDTO}
                banners={banners ?? {} as IBannerDTO[]}
                isOpen={location?.hash === '#addBanner'}
                onClose={() => {
                    changeHash('');
                    setBanner(undefined);
                    getBanners(seeButton);
                }}
            />
        </>
    );
}
