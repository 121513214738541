import React, { createContext, useContext, useState } from 'react';

const TopbarCollapseContext = createContext();

export const useTopbarCollapse = () => useContext(TopbarCollapseContext);

export const TopbarCollapseProvider = ({ children }) => {
    const elemento = document.querySelector('html');
    const [isButtonCollapsed, setIsButtonCollapsed] = useState(false);


    const toggleTopbar = () => {
        elemento.classList.toggle('topbar_open');
        setIsButtonCollapsed(prevState => !prevState);
    };

    return (
        <TopbarCollapseContext.Provider value={{ toggleTopbar }}>
            {children}
        </TopbarCollapseContext.Provider>
    );
};
