import { Masks } from "@hooks/functions/Masks/Masks";
import Skeleton from "react-loading-skeleton";

interface IProps {
  title: string;
  value: string | number;
  isLoading?: boolean;
}

const masks = new Masks();
export function ContentBetween({ title, value, isLoading }: IProps) {
  return (
    <>
      {isLoading ?
        <Skeleton count={2} />
        :
        <div className="row mb-2">
          <div className="col-6 col-md-6">
            <span className="h6">{title}</span>
          </div>
          <div className="col-6 col-md-6 text-end" >
            <span className="h6">{typeof value === "number" ? masks.brlCurrency(value) : value}</span>
          </div>
        </div>
      }
    </>
  )
}