import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { create } from 'zustand';
import { IToastifyDTO } from '../dtos/toastify-dto';

type IActionsProps = {
  pushToastify: (value: IToastifyDTO) => void;
  unshiftToastify: () => void;
};

type IStoreProps = {
  queue: IToastifyDTO[];
  actions: IActionsProps;
};

export const ZuToastifyStore = create<IStoreProps>((set) => ({
  queue: [],
  actions: {
    pushToastify: (value) => {
      set((prev) => ({ queue: [...prev.queue, value] }));
      const toastType = value.type || 'default';

      const toastOptions = {
        position: value.position || "top-right",
        autoClose: value.autoClose || 5000,
        hideProgressBar: value.hideProgressBar || false,
        closeOnClick: value.closeOnClick || true,
        pauseOnHover: value.pauseOnHover || true,
        draggable: value.draggable || true,
        progress: undefined,
        theme: value.theme || "light",
      };

      switch (toastType) {
        case 'success':
          toast.success(value.message, toastOptions);
          break;
        case 'info':
          toast.info(value.message, toastOptions);
          break;
        case 'warning':
          toast.warning(value.message, toastOptions);
          break;
        case 'error':
          toast.error(value.message, toastOptions);
          break;
        default:
          toast(value.message, toastOptions);
          break;
      }
    },
    unshiftToastify: () =>
      set((prev) => {
        const queue = [...prev.queue];
        return { queue: queue.slice(1, queue.length) };
      }),
  },
}));
