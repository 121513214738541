import { Close } from "@components/buttons/close";
import { ConfirmButton } from "@components/buttons/confirm-button";
import { InputCurrency } from "@components/forms/input-currency";
import { yupResolver } from '@hookform/resolvers/yup';
import { ZuToastifyStore } from "@hooks/stores/zustand-toastify-store";
import { api } from "@service/api";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from 'yup';

interface IProps {
  customerId?: string;
  isOpen: boolean;
  onClose: () => void;
}

interface IForm {
  value: number;
  locked: 'y' | 'n';
  rollover_amount?: number;
  note: string;
  otp?: string;
  pin?: string;
}

export function ModalAddBalance({ customerId, isOpen, onClose }: IProps) {
  const { actions: { pushToastify } } = ZuToastifyStore()

  const [animate, setAnimate] = useState(false);
  const [hasRollover, setHasRollover] = useState<boolean>(false);

  const scheme = yup.object().shape({
    value: yup.number()
      .positive('O valor deve ser positivo')
      .min(1, 'O valor deve ser maior que R$ 1,00')
      .required('Digite o valor a ser depositado.'),

    locked: yup.mixed<'y' | 'n'>()
      .required('Selecione o tipo da carteira!')
      .oneOf(["y", "n"], 'Escolha uma carteira válida!'),

    // rollover_amount: yup.number()
    //   .when('wallet', {
    //     is: 'n',
    //     then: (schema) => schema.required('Escolha quantas vezes será este rollover').positive("Digite um número positivo!"),
    //     otherwise: (schema) => schema.notRequired(),
    //   }),

    note: yup.string().required('Digite o motivo de você estar adicionando saldo para este usuário!')
  });

  const { register, reset, setValue, watch, handleSubmit, formState: { errors } } = useForm<IForm>({
    resolver: yupResolver(scheme),
    mode: 'onSubmit'
  });
  const value = watch('value');

  function handleClose() {
    setAnimate(false);
    reset();
    setValue("value", 0);
    setTimeout(() => onClose(), 300);
  };

  function handleEscape(evt: KeyboardEvent) {
    if (evt.key === 'Escape' || evt.key === 'Esc' || evt.keyCode === 27) {
      handleClose();
    }
  };

  async function handleAddBalance(data: IForm) {
    try {
      await api.post("/manager/customer/cash/manipulation", {
        customerId,
        type: "c",
        otpAuth: data.otp,
        pin: data.pin,
        locked: data.locked,
        amount: data.value,
        // rollover_amount: data?.rollover_amount ?? null,
        note: data.note
      });

      pushToastify({
        message: "Sucesso! Ao requsitar inserção!",
        type: 'success',
        theme: 'dark',
      });

      setTimeout(() => handleClose(), 300);
    } catch (err: any) {
      reset();
      setValue("value", 0);

      pushToastify({
        message: err?.response?.data.message ?? "",
        type: 'error',
        theme: 'dark',
      });
    }
  }

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setAnimate(true), 10);
      document.addEventListener('keydown', handleEscape);
      setHasRollover(false);
      reset();
      setValue("value", 0);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    } else {
      setAnimate(false);
    }
  }, [isOpen]);

  return (
    <>
      <div className={`modal fade ${animate && isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} aria-labelledby="staticBackdropLabel" aria-hidden={!isOpen}>
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="contentModallLabel">Adicionar Saldo</h5>
              <Close onPress={handleClose} />
            </div>
            <div className="modal-body">
              <form onSubmit={(e) => {
                e.preventDefault();
              }}>
                <div className="row">
                  <div className="col-md-12">
                    <InputCurrency
                      animated
                      label="Valor"
                      placeholder=""
                      prefix="R$"
                      value={value}
                      onChangeTextOut={(v: number, valueFormat?: string) => {
                        setValue("value", v);
                      }}
                      min={0}
                      errorMessage={String(errors?.value?.message || "")} />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <select
                      className={`form-select ${errors?.locked?.message ? 'is-invalid' : ''}`}
                      {...register('locked')}
                      // onChange={(e) => (e.target.value == "y") ? setHasRollover(true) : setHasRollover(false)}
                      aria-label="Default select example" defaultValue="">
                      <option value="" disabled>Selecione a carteira</option>
                      <option value="n">Liberada</option>
                      <option value="y">Bloqueada</option>
                    </select>

                    {errors?.locked?.message &&
                      <div className="invalid-feedback">
                        {errors?.locked?.message}
                      </div>
                    }
                  </div>
                </div>

                {/* {hasRollover ?
                  <>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Input animated label="Multiplicador" placeholder="" register={register('rollover_amount')} errorMessage={String(errors?.rollover_amount?.message || "")} />
                      </div>
                    </div>
                  </>
                  : ""} */}

                <div className={`row ${hasRollover ? "" : "mt-3"} `}>
                  <div className="col-md-12">
                    <div className="form-floating">
                      <textarea {...register('note')} className={`form-control ${errors?.note?.message ? 'is-invalid' : ''}`}></textarea>
                      <label>Nota</label>
                    </div>

                    {errors?.note?.message &&
                      <div className="text-danger">
                        {errors?.note?.message}
                      </div>
                    }
                  </div>
                </div>

                <div className="row">
                  <div className="mt-2 col-md-12 text-end">
                    <ConfirmButton
                      validations="doubleCheck"
                      textConfirmation="Por favor, confirme sua Senha, OTP e o envio do Formulário para a requisição ser feita!"
                      needConfirm={true}
                      onConfirm={(e, data) => {
                        if (e == true) {
                          setValue("otp", data?.otp);
                          setValue("pin", data?.pin);
                          handleSubmit(handleAddBalance)();
                        }
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal-backdrop fade ${animate && isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }}></div>
    </>
  );
}