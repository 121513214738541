
interface IProps {
  title: string;
  value: string | number;
  personalized?: boolean;
}

export function SeparatedInfo({ title, value, personalized, ...rest }: IProps) {
  return (
    <>
      <div
        {...(personalized ? rest : { className: "d-flex justify-content-between align-items-center gap-2 border-bottom" })}
      >
        <h6 className="text-muted fw-bold">{title}</h6>
        <h5 className="text-secondary fw-normal">{value}</h5>
      </div>
    </>

  )
}