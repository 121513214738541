
import { DialogConfirm } from "@components/modals/modal-dialog/confirmation";
import { ReactNode, useState } from "react";

interface IProps {
  title?: ReactNode | string;
  className?: string;
  textConfirmation: string;
  needConfirm?: boolean;
  onConfirm?: (e: boolean, data?: { otp: string, pin: string }) => void;
  validations?: "doubleCheck" | "otp" | "pin";
  children?: ReactNode;
}

export function ConfirmButton({ title, className, validations, textConfirmation, needConfirm, onConfirm, children }: IProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <DialogConfirm
        setConfirm={needConfirm ? onConfirm : (e: boolean) => { }}
        validations={validations}
        textConfirmation={textConfirmation}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />

      <button
        className={className ? `${className}` : "btn btn-success"}
        type="button"
        onClick={() => setIsOpen(true)}
      >
        {children}
        {title ? title : "Finalizar"}
      </button>
    </>
  )
}