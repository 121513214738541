import { LoadingSkeleton } from "@components/skeleton-loading";
import { Masks } from "@hooks/functions/Masks/Masks";

interface IProps {
  title: string;
  subtitle?: string;
  value: string | number;
  isLoading?: boolean;
  className?: string;
}

const masks = new Masks();
export function CardInfo({ title, subtitle, value, isLoading, className }: IProps) {

  return (
    <>
      <div className="card-header">
        <div className="card-title">
          <span>{title}</span>
        </div>
        <div className="card-category p-0 m-0">
          <small>{subtitle}</small>
        </div>
      </div>
      <div className={`card-body ${className}`}>
        {isLoading ? (
          <div className="w-100">
            <LoadingSkeleton count={2} />
          </div>
        ) : (
          <h4 className="fw-bolder">{typeof value === "number" ? masks.brlCurrency(value) : value}</h4>
        )}
      </div>
    </>
  )
}