import { Button } from '@components/buttons/button';
import { Close } from '@components/buttons/close';
import { Input } from '@components/forms/input';
import { LoadingSkeleton } from '@components/skeleton-loading';
import { yupResolver } from '@hookform/resolvers/yup';
import { IBannerEditDTO } from '@hooks/dtos/forms/IBannerEditDTO';
import { IBannerConfigDTO, IBannerDTO } from '@hooks/dtos/IBannerDTO';
import { ZuToastifyStore } from '@hooks/stores/zustand-toastify-store';
import { api } from "@service/api";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    banner: IBannerDTO;
    banners: IBannerDTO[];
}

export function ModalEditBanner({ isOpen, onClose, banner, banners }: IProps) {
    const [animate, setAnimate] = useState(false);
    const [bannerConfig, setBannerConfig] = useState<IBannerConfigDTO>({});
    const [isOrderLoading, setIsOrderLoading] = useState(true);
    const { actions: { pushToastify } } = ZuToastifyStore();
    
    const scheme = yup.object().shape({
        name: yup
            .string()
            .required('Declare um nome ou apelido para facilitar a identificação.'),
        link: yup
            .string()
            .url('O link precisa ser uma URL válida.'),
        order: yup
            .number()
            .required('A ordem é obrigatória'),
    });

    const { register, reset, handleSubmit, formState: { errors } } = useForm<IBannerEditDTO>({
        resolver: yupResolver(scheme) as any,
        mode: 'onSubmit',
        reValidateMode: 'onBlur'
    });

    async function handleUpdate(data: IBannerEditDTO) {
        try {
            await api.post('/manager/customization/banner/update', {
                name: data?.name,
                bannerId: banner?.id,
                link: data?.link,
                status: data?.status,
                order: data?.order
            });

            pushToastify({
                message: `Informações atualizadas com sucesso.`,
                type: 'success',
                theme: "dark",
            });
        } catch (error: any) {
            pushToastify({
                message: error?.response?.data?.message,
                type: 'error',
                theme: "dark",
            });
        }
    }

    async function checkOrder(order: number) {
        const exists = banners.some(_banner => _banner.id !== banner.id && _banner.order === order && _banner.status === "a");
        if (exists) {
            pushToastify({
                message: "Esta ação trocará de posição com banner da posição selecionada!",
                type: 'info',
                theme: "dark",
                pauseOnHover: true
            });
        } else {
            pushToastify({
                message: "Posição válida, não há banner ocupando esta ordem.",
                type: 'success',
                theme: "dark",
                pauseOnHover: true
            });
        }
    }

    async function getBannerConfigs() {
        try {
            const result = await api.get('/manager/customization/banner/config');
            const bannerConfigValue = result.data?.value;
            if (bannerConfigValue) {
                const configBanners = JSON.parse(bannerConfigValue)?.banner;
                if (configBanners) {
                    setIsOrderLoading(false);
                    setBannerConfig(configBanners);
                }
                return;
            }

            pushToastify({
                message: "Houve um erro ao carregar as configurações de Banners do Sistema!",
                type: 'error',
                theme: "dark",
                pauseOnHover: true
            });
        } catch (err: any) {
            console.error(err);
        }
    }

    function handleClose() {
        setAnimate(false);
        setTimeout(() => onClose(), 300);
    };

    function handleEscape(evt: KeyboardEvent) {
        if (evt.key === 'Escape' || evt.key === 'Esc' || evt.keyCode === 27) {
            handleClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            
            setTimeout(() => setAnimate(true), 10);
            document.addEventListener('keydown', handleEscape);

            // --- Reset the Inputs Values --- //
            reset({name: banner?.name, link: banner?.link, order: banner?.order})

            // --- Load the System Banner Configs --- //
            getBannerConfigs();

            return () => {document.removeEventListener('keydown', handleEscape);};         
        } else {
            setAnimate(false);
        }
    }, [isOpen]);

    return (
        <>
            <div className={`modal fade ${animate && isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} aria-labelledby="staticBackdropLabel" aria-hidden={!isOpen}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="contentModallLabel">Editar Banner #{banner.id}</h5>
                            <Close onPress={handleClose} />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit(handleUpdate)();
                            }}>
                                <div className="row gap-3">
                                    {/* Name */}
                                    <div className="col-md-12">
                                        <Input
                                            animated
                                            label="Nome"
                                            placeholder=""
                                            type='text'
                                            register={register('name')}
                                            errorMessage={String(errors?.name?.message || "")}
                                        />
                                    </div>
                                    {/* URL | Link */}
                                    <div className="col-md-12">
                                        <Input
                                            animated
                                            label="URL"
                                            placeholder=""
                                            type='text'
                                            register={register('link')}
                                            errorMessage={String(errors?.link?.message || "")}
                                        />
                                    </div>
                                    {/* Ordination */}
                                    {isOrderLoading ? (
                                        <div className="w-100">
                                            <LoadingSkeleton count={1} />
                                        </div>
                                    ) : (
                                        <div className="col-md-12">
                                            <div className="form-floating">
                                                <select
                                                    className={`form-select`}
                                                    {...register('order')}
                                                    defaultValue={banner?.order}
                                                    onChange={(e) => checkOrder(Number(e.target.value))}
                                                >
                                                    {bannerConfig?.home?.max ? (
                                                        Array.from({ length: bannerConfig.home.max }, (_, index) => (
                                                            <option key={index} value={index + 1}>
                                                                {`${index + 1}º`}
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option value="">Carregando...</option>
                                                    )}
                                                </select>
                                                <label>Ordenação</label>
                                            </div>
                                        </div>
                                    )}
                                    {/* Status */}
                                    <div className="col-md-12 mt-3">
                                        <div className="form-floating">
                                            <select
                                                className={`form-select`}
                                                {...register('status')}
                                                defaultValue={banner.status}
                                            >
                                                {["a", "i"].map(index => (
                                                    <option key={index} value={index}>
                                                        {`${index === "a" ? `Ativo` : `Inativo`}`}
                                                    </option>
                                                ))}
                                            </select>
                                            <label>Status</label>
                                        </div>
                                    </div>
                                    {/* Banner */}
                                    <div className="col-md-12">
                                        <img src={`${banner.image}&size=lg`} alt="" className="rounded-2 img-fluid" style={{
                                            maxWidth: '100%',
                                            height: 'auto',
                                            border: '1px solid #ddd',
                                            backgroundColor: '#f0f0f0'
                                        }} />
                                    </div>

                                    <div className="col-md-12 mb-2 col-12 text-end">
                                        <Button type="submit" children="Salvar" className="btn btn-success py-3 px-5" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className={`modal-backdrop fade ${animate && isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }}></div>
        </>
    )
}