
interface IProps {
    children?: any;
    className?: string;
    onPress: () => void;
}

export function Close({ children, className, onPress }: IProps) {

    return (
        <button type="button" className='text-end btn btn-close' onClick={onPress}></button>
    )
}