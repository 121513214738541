import React, { createContext, useContext, useState } from 'react';

const SidebarCollapseContext = createContext();

export const useSidebarCollapse = () => useContext(SidebarCollapseContext);

export const SidebarCollapseProvider = ({ children }) => {
    const elemento = document.querySelector('html');
    const [isButtonCollapsed, setIsButtonCollapsed] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const toggleSidebar = () => {
        elemento.classList.toggle('nav_open');
        setIsButtonCollapsed(prevState => !prevState);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const isCollapsed = isButtonCollapsed && !isHovered;

    return (
        <SidebarCollapseContext.Provider value={{ isCollapsed, toggleSidebar, handleMouseEnter, handleMouseLeave }}>
            {children}
        </SidebarCollapseContext.Provider>
    );
};
