import { useEffect, useState } from "react";

interface IProps {
  textConfirmation?: string;
  isOpen: boolean;
  setConfirm?: (e: boolean, data?: { otp: string, pin: string }) => void;
  onClose: () => void;
  validations?: "doubleCheck" | "otp" | "pin";
}

export function DialogConfirm({ validations, isOpen, textConfirmation, onClose, setConfirm }: IProps) {
  const [animate, setAnimate] = useState(false);
  const [pin, setPin] = useState("");
  const [otp, setOtp] = useState("");

  function handleCancel() {
    setPin("");
    setOtp("");
    setAnimate(false);
    setTimeout(() => onClose(), 300);
  };

  function handleEscape(evt: KeyboardEvent) {
    if (evt.key === 'Escape' || evt.key === 'Esc' || evt.keyCode === 27) {
      handleCancel();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => setAnimate(true), 10);
      document.addEventListener('keydown', handleEscape);
      setPin("");
      setOtp("");

      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    } else {
      setAnimate(false);
    }
  }, [isOpen]);

  return (
    <>
      <div className={`modal fade ${animate && isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }} aria-labelledby="staticBackdropLabel" aria-hidden={!isOpen}>
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" onClick={() => {
                if (setConfirm) {
                  setConfirm(false)
                };
                handleCancel();
              }}>
              </button>
            </div>

            <div className="modal-body text-start">
              <div className="row mb-5">
                <div className="col-md-12 text-center">
                  <span className="fs-5">
                    {textConfirmation}
                  </span>
                </div>
              </div>

              <div className="row gap-3">
                {(validations == "pin" || validations == "doubleCheck") &&
                  <div className="col-md-12">
                    <div className="form-floating">
                      <input
                        type="password"
                        className="form-control"
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                      />
                      <label>Senha</label>
                    </div>
                  </div>
                }

                {(validations == "otp" || validations == "doubleCheck") &&
                  <div className="col-md-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                      <label>OTP</label>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-sm btn-danger" onClick={() => {
                if (setConfirm) {
                  setConfirm(false)
                };
                handleCancel();
              }}>
                Cancelar
              </button>
              {(validations)
                ? (
                  (validations == "doubleCheck" && otp?.length > 0 && pin?.length > 0)
                  ||
                  (validations == "otp" && otp?.length > 0)
                  ||
                  (validations == "pin" && pin?.length > 0)
                ) &&
                <button type="button" className="btn btn-sm btn-success" onClick={() => {
                  if (setConfirm) {
                    setConfirm(true, { pin, otp })
                  };
                  handleCancel();
                }}>
                  Confirmar
                </button>
                :
                <button type="button" className="btn btn-sm btn-success" onClick={() => {
                  if (setConfirm) {
                    setConfirm(true)
                  };
                  handleCancel();
                }}>
                  Confirmar
                </button>
              }
            </div>
          </div>
        </div>
      </div>

      <div className={`modal-backdrop fade ${animate && isOpen ? 'show' : ''}`} style={{ display: isOpen ? 'block' : 'none' }}></div>
    </>
  );
}