import NavbarBottom from '@components/layout/navbar-bottom';
import NavbarTop from '@components/layout/navbar-top';
import { useSidebarCollapse } from '@hooks/contexts/SidebarCollapseContext';
import AppRoutes from 'routes';
import NavbarLeft from '../navbar-left';

export default function Content() {
    const { isCollapsed } =useSidebarCollapse() ; // Use context

    return (
        <div className={`wrapper ${isCollapsed ? 'sidebar_minimize' : ''}`}>
            <NavbarLeft />
            <div className="main-panel">
                <NavbarTop />
                <div className="container">
                    <div className="page-inner">
                        <AppRoutes />
                    </div>
                </div>
                <NavbarBottom />
            </div>
        </div>
    )
}