import React from 'react';

import { CgSpinner } from "react-icons/cg";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
    loading?: boolean;
}

export function Button({ children, loading, ...rest }: IProps) {
    return (
        <button 
            {...rest}
            disabled={loading}
        >
            {loading ? <CgSpinner className="loading-icon" /> : null} {children}
        </button>
    );
}
