import { Card } from "@components/cards/card";
import { CardInfo } from "@components/cards/card-info";
import { ContentBetween } from "@components/contents/content-between";
import { Pagination } from "@components/pagination";
import { useState } from "react";
import { useParams } from "react-router-dom";

const status = {
    p: {
        color: "info",
        text: "Pendente",
    },
    x: {
        color: "success",
        text: "Cancelado"
    },
    w: {
        color: "success",
        text: "Ganho"
    },
    hw: {
        color: "warning",
        text: "Ganho parcial"
    },
    hl: {
        color: "warning",
        text: "Perda parcial"
    },
    l: {
        color: "danger",
        text: "Perda",
    },
}

const games = {

    1: {
        text: "Yolo"
    },

}

export function GameInfo() {
    const [page, setPage] = useState(1) // apenas para o pagination funcionar
    const { id } = useParams<{id : "1"}>();

    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <Card className="position-relative">
                        <CardInfo
                            isLoading={false}
                            title="Total de apostas"
                            value={String(0)}
                        />
                        <span className="position-absolute p-2 end-0 bg-info text-white rounded-end rounded-bottom-0">
                            Diário
                        </span>
                    </Card>
                </div>

                <div className="col-md-3">
                    <Card className="position-relative">
                        <CardInfo
                            isLoading={false}
                            title="Total de apostas"
                            value={String(0)}
                        />
                        <span className="position-absolute p-2 end-0 bg-info text-white rounded-end rounded-bottom-0">
                            Semanal
                        </span>
                    </Card>
                </div>

                <div className="col-md-3">
                    <Card className="position-relative">
                        <CardInfo
                            isLoading={false}
                            title="Total de apostas"
                            value={String(0)}
                        />
                        <span className="position-absolute p-2 end-0 bg-info text-white rounded-end rounded-bottom-0">
                            Mensal
                        </span>
                    </Card>
                </div>

                <div className="col-md-3">
                    <Card className="position-relative">
                        <CardInfo
                            isLoading={false}
                            title="Total de apostas"
                            value={String(0)}
                        />
                        <span className="position-absolute p-2 end-0 bg-info text-white rounded-end rounded-bottom-0">
                            Anual
                        </span>
                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <Card className="position-relative">
                        <CardInfo
                            isLoading={false}
                            title="Taxas internas"
                            value={0}
                        />
                    </Card>
                </div>

                <div className="col-md-3">
                    <Card className="position-relative">
                        <CardInfo
                            isLoading={false}
                            title="Taxas provedor"
                            value={0}
                        />
                    </Card>
                </div>

                <div className="col-md-3">
                    <Card className="position-relative">
                        <CardInfo
                            isLoading={false}
                            title="Total de apostas"
                            value={String(0)}
                        />
                    </Card>
                </div>

                <div className="col-md-3">
                    <Card className="position-relative">
                        <CardInfo
                            isLoading={false}
                            title="Total apostado"
                            value={0}
                        />

                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <Card>
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-10">
                                    <h4 className="card-title">Apostas {id !== undefined ? games[id].text : 'ID inválido'}</h4>
                                    <p className="card-category">Lista de todas as apostas</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <div id="basic-datatables_wrapper" className="dataTables_wrapper container-fluid dt-bootstrap4">
                                    <div id="basic-datatables_length" className="dataTables_length">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr role="row">
                                                    <th scope="col" className="fs-6">#</th>
                                                    <th>Usuário</th>
                                                    <th>Valor</th>
                                                    <th>Round</th>
                                                    <th>Taxa interna</th>
                                                    <th>Taxa Provedor</th>
                                                    <th>Status</th>
                                                    <th>Lucro</th>
                                                    <th>Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={10} className="text-center">Nenhum registro encontrado.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <Pagination page={1} setPage={setPage} totalPages={1} maxPageShow={1} />
                        </div>
                    </Card>
                </div >
            </div >
        </>
    )
}